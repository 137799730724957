// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBR-XdbhtntbeYrPErd5JFExyHqgs1vKQg",
    authDomain: "seskat-19774.firebaseapp.com",
    projectId: "seskat-19774",
    storageBucket: "seskat-19774.appspot.com",
    messagingSenderId: "886366962156",
    appId: "1:886366962156:web:5e318bc0322a09a99400c9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);