import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import { font } from "./signika";
import { renderToStaticMarkup } from "react-dom/server"

import html2canvas from 'html2canvas';

import jsPDF from 'jspdf';

const content_text = {
    de: {
        congrats: "Herzlichen Glückwunsch zum Ausfüllen unseres Fragebogens.",
        total: "Ihre Gesamtpunktzahl ist ",
        score_in: "Deine Punktzahl drin ",
        is: " ist ",
        p1: "Nochmals vielen Dank für die Beantwortung unseres Fragebogens und für Ihre aktive Teilnahme am SESKAT-Projekt. Wir hoffen, dass es für Sie von Interesse war und dass es Ihnen geholfen hat, sich der Aspekte bewusst zu werden, die Sie bereits beherrschen und derer, die Sie im emotionalen Management Ihrer Ausbildung verbessern können. Sie können uns in unseren sozialen Netzwerken und auf unserer",
        link_text: "website folgen"
    },
    ro: {
        congrats: "Felicitări pentru finalizarea chestionarului nostru.",
        total: "Scorul tau este ",
        score_in: "Scorul tau în ",
        is: " este ",
        p1: "Vă mulțumim din nou pentru răspunsul la chestionar și pentru participarea activă la Proiectul SESKAT. Sperăm că v-a interesat și că v-a ajutat să deveniți conștienți de aspectele pe care le-ați stăpânit deja și de cele pe care le puteți îmbunătăți în managementul emoțional al antrenamentului. Ne puteți urmări pe rețelele noastre de socializare și pe",
        link_text: "pagina web"
    },
    gb: {
        congrats: "Congratulations on finishing our questionnaire.",
        total: "Your total score is ",
        score_in: "Your score in ",
        is: " is ",
        p1: "Thank you again for answering our questionnaire and for actively participating in the SESKAT Project. We hope it has been of interest to you and that it has helped you to become aware of the aspects that you have already mastered and those that you can improve in the emotional management of your training. You can follow us on our social networks and on the",
        link_text: "website"
    },
    it: {
        congrats: "Congratulazioni per aver completato il nostro questionario.",
        total: "Il tuo punteggio totale è ",
        score_in: "Il tuo punteggio in ",
        is: " è ",
        p1: "Ti ringraziamo ancora per aver risposto al nostro questionario e per aver partecipato attivamente al Progetto SESKAT. Ci auguriamo che sia stato di tuo interesse e che ti abbia aiutato a prendere coscienza degli aspetti che già padroneggi e di quelli che puoi migliorare nella gestione emotiva del processo formativo. Puoi seguirci sui nostri social network e sul",
        link_text: "sito web"
    },
    es: {
        congrats: "Enhorabuena por terminar nuestro cuestionario.",
        total: "Tu puntuación total es ",
        score_in: "Tu puntuación en ",
        is: " es ",
        p1: "Gracias de nuevo por contestar nuestro cuestionario y por participar activamente en el Proyecto SESKAT. Esperamos que haya sido de tu interés y que te haya servido para tomar conciencia de los aspectos que ya dominas y los que puedes mejorar en la gestión emocional de tu formación. Puedes seguirnos en nuestras redes sociales y en la",
        link_text: "web"
    },
    pl: {
        congrats: "Gratulujemy ukończenia naszego kwestionariusza.",
        total: "Twój całkowity wynik to ",
        score_in: "Twój wynik w ",
        is: " jest ",
        p1: "Jeszcze raz dziękujemy za wypełnienie naszej ankiety i aktywny udział w Projekcie SESKAT. Mamy nadzieję, że okazał on się dla Ciebie interesujący i pomógł Ci uświadomić sobie aspekty, które już opanowałeś i te nad którymi  możesz popracowac, przydatne w zarządzaniu emocjami podczas Twoich szkoleń. Możesz śledzić nas w naszych mediach społecznościowych i na stronie",
        link_text: "internetowej"
    }
}

export default function Score(props) {
    const canvas_ref = useRef(null);
    const pdf_canvas_ref = useRef(null);
    const [score_state, set_score_state] = useState({ current_score: Object.keys(props.score.skill_groups)[0] });

    const all_html_ref = useRef(null);

    const get_canvas_config = function (maintainAspectRatio = false) {
        const labels = Object.keys(props.score.skill_groups);
        let values = Object.values(props.score.skill_groups);
        const data = {
            labels: labels,
            datasets: [{
                data: values,
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(255, 99, 132)'
            }]
        };

        const config = {
            type: 'radar',
            data: data,
            options: {
                maintainAspectRatio: maintainAspectRatio,

                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: props.score.max_per_skill_group,
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    }, tooltips: {
                        enabled: false,
                        callbacks: {
                            label: function (tooltipItem) {
                                return tooltipItem.yLabel;
                            }
                        }
                    },
                },

                elements: {
                    line: {
                        borderWidth: 3
                    }
                }
            },
        };

        return config;
    }

    useEffect(() => {
        const canvas = canvas_ref.current;


        new Chart(
            canvas,
            get_canvas_config()
        );


    }, [props.score.skill_groups, props.score.max_per_skill_group]);

    const high_score_class = " border-dashed border-green-500";
    const medium_score_class = " border-dashed border-yellow-500";
    const low_score_class = " border-dashed border-red-500";

    const high_score_ring = " border-solid  border-green-500";
    const medium_score_ring = " border-solid  border-yellow-500";;
    const low_score_ring = " border-solid  border-red-500";


    let score_color = {}
    const card_class = "overflow-hidden rounded-lg px-4 py-5 sm:p-6"

    props.score.skill_groups_classes = {}
    for (const cl in props.score.skill_groups) {
        const score = props.score.skill_groups[cl];
        const max_score = props.score.max_per_skill_group;
        const percentile = score / max_score * 100;
        props.score.skill_groups_classes[cl] = card_class;
        score_color[cl] = {};

        if (percentile >= 90) {
            score_color[cl].inner = high_score_class;
            score_color[cl].ring = high_score_ring;
        } else if (percentile >= 60 && percentile < 90) {
            score_color[cl].inner = medium_score_class;
            score_color[cl].ring = medium_score_ring;
        } else {
            score_color[cl].inner = low_score_class;
            score_color[cl].ring = low_score_ring;
        }


        if (score_state.current_score === cl) {
            props.score.skill_groups_classes[cl] += " border-4" + score_color[cl].ring;
        } else {
            props.score.skill_groups_classes[cl] += " border-4" + score_color[cl].inner
        }

    }

    let total = 0;
    for (const v of Object.values(props.score.score)) {
        total += v;
    }

    let set_current_score = function (score) {
        set_score_state({ current_score: score })
    }

    let on_save = function () {

        const doc = new jsPDF('l', 'pt');


        doc.addFileToVFS('Signika-Regular-normal.ttf', font);
        doc.addFont('Signika-Regular-normal.ttf', 'Signika-Regular', 'normal');
        doc.setFont("Signika-Regular");

        doc.setFontSize(20);
        let text = content_text[props.lang].congrats;
        let yoffset = 40;
        let xoffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)
        doc.text(text, xoffset, yoffset);
        yoffset += 50;

        doc.setFontSize(15);
        text = content_text[props.lang].total + total + "/" + props.score.max_possible;
        xoffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
        doc.text(text, xoffset, yoffset);
        yoffset += 100;

        doc.addImage("seskat.png", "PNG", 330, yoffset, 200, 200);
        doc.addPage();
        yoffset = 40;

        for (const k in props.score.skill_groups) {
            doc.setFontSize(18);


            const percentile = props.score.skill_groups[k] / props.score.max_per_skill_group * 100;
            if (percentile >= 90) {
                doc.setTextColor("#22c55e");
            } else if (percentile >= 60 && percentile < 90) {
                doc.setTextColor("#eab308");
            } else {
                doc.setTextColor("#ef4444");
            }
            text = content_text[props.lang].score_in + k + content_text[props.lang].is + props.score.skill_groups[k] + "/" + props.score.max_per_skill_group;
            xoffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
            doc.text(text, xoffset, yoffset);
            yoffset += 18;


            doc.setTextColor("#6b7280");

            doc.setFontSize(11);
            text = props.score.messages[k][props.lang];
            let lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 100);

            for (let l in lines) {
                xoffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(lines[l]) * doc.internal.getFontSize() / 2);
                doc.text(lines[l], xoffset, yoffset);
                yoffset += 15;
            }
            yoffset += 30;
        }


        doc.addPage();
        yoffset = 200;

        doc.setFontSize(20);
        doc.setTextColor("#6b7280");
        text = content_text[props.lang].p1;
        let lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 100);
        for (let l in lines) {
            xoffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(lines[l]) * doc.internal.getFontSize() / 2);

            doc.text(lines[l], xoffset, yoffset);
            yoffset += 25;
        }

        text = content_text[props.lang].link_text;
        doc.setFontSize(40);
        xoffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);

        doc.link(xoffset, yoffset, 140, 45, { url: "http://seskat-erasmus.site/" });
        doc.setTextColor("#06b6d4");
        yoffset += 40;
        doc.text(text, xoffset, yoffset);

        doc.save("seskat_result.pdf");
    }



    console.log("got props: ", props.score);

    return (

        <>
            <div className="justify-center text-center">
                <h3 className="text-lg font-medium leading-6 text-gray-900">{content_text[props.lang].congrats}</h3>
            </div>

            <div className="justify-center text-center grid">

                <h3 className="text-lg mt-10 font-medium leading-6 text-gray-900">{content_text[props.lang].total} {total} / {props.score.max_possible}</h3>
                <div className="container justify-center text-center ">

                    <div>
                        <canvas id="radar" ref={canvas_ref}></canvas>
                    </div>

                    <div>
                        <div className=" grid grid-cols-3 gap-5">
                            {Object.keys(props.score.skill_groups).map((k) => (
                                <div key={k} className={props.score.skill_groups_classes[k]} onClick={set_current_score.bind(null, k)}>
                                    <div className="truncate text-sm font-medium text-gray-500">{k}</div>
                                    <div className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{props.score.skill_groups[k]} / {props.score.max_per_skill_group} </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 mt-10 border-4 border-dashed border-indigo-600">
                        <p className=" text-xl leading-8 text-gray-500">{props.score.messages[score_state.current_score][props.lang]}</p>
                        <br></br>
                        <div class="grid grid-cols-5 divide-x">
                            {
                                props.score.links[score_state.current_score][props.lang].map((l, i) => <div > <a href={l} target="_blank" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">link {i + 1}</a> </div>)
                            }
                        </div>

                    </div>

                    <p className="mt-8 text-md leading-8 text-gray-500"> {content_text[props.lang].p1} <a className="text-indigo-600" href="http://seskat-erasmus.site/">{content_text[props.lang].link_text}</a>.</p>
                </div>



                <div className="flex justify-center mt-5">
                    <button
                        onClick={on_save}
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                        </svg>

                        <span className="mt-3">Save to PDF</span>
                    </button>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-center">
                    <button
                        onClick={props.on_back}
                        type="button"
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >


                        Back
                    </button>
                </div>
            </div>
        </>
    );
}