import Questions, { local_storage_key } from "./Questions";
import Score from "./Score";
import Introduction from "./Introduction";
import React, { useState } from 'react';

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import Flag from 'react-world-flags';

const supported_languages = ["en", "it", "es", "pl", "ro", "de"];
const lang_key = "lang";

function lang_to_country(lang) {
  if (lang == "it") {
    return "it";
  } else if (lang == "es") {
    return "es";
  } else if (lang == "pl") {
    return "pl";
  } else if (lang == "ro") {
    return "ro";
  } else if (lang == "de") {
    return "de";
  }

  return "gb";
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function get_country_lang() {

  let browser_lang = navigator.language;

  if (typeof browser_lang === 'string' && browser_lang.length >= 2) {
    browser_lang = browser_lang.substring(0, 2);
  } else {
    browser_lang = "en";
  }

  if (!supported_languages.includes(browser_lang)) {
    browser_lang = "en";
  }

  let current_language = localStorage.getItem(lang_key);

  if (typeof current_language === 'string' && current_language.length >= 2) {
    browser_lang = current_language.substring(0, 2);
  }

  return browser_lang;
}

export default function App() {
  let browser_lang = get_country_lang();

  let contry_flag = lang_to_country(browser_lang);

  let [state, setState] = useState({
    score: 0,
    active_page: 0,
    lang: contry_flag,
  });

  let on_form_finished = (score) => {
    setState({
      score: score,
      active_page: 2,
      lang: state.lang,
    })
  }

  let on_back = () => {
    localStorage.removeItem(local_storage_key);
    setState({
      score: 0,
      active_page: 1,
      lang: state.lang,
    })
  }

  let on_start = () => {
    setState({
      score: 0,
      active_page: 1,
      lang: state.lang,
    })
  }

  let on_flag_clicked = (lang) => {
    localStorage.setItem(lang_key, lang);
    setState({
      score: state.score,
      active_page: state.active_page,
      lang: lang,
    })


    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set("lang", lang);
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
  }


  const lang = new URLSearchParams(document.location.search).get("lang");

  if (typeof lang === 'string' && lang.length >= 2 && supported_languages.includes(lang) && lang_to_country(lang) !== state.lang) {
    on_flag_clicked(lang_to_country(lang));
  }

  let current_page = (<Introduction lang={state.lang} on_start={on_start}></Introduction>);

  if (state.active_page === 2) {
    current_page = (<Score lang={state.lang} score={state.score} on_back={on_back}></Score>)
  } else if (state.active_page == 1) {
    current_page = (<Questions lang={state.lang} on_form_finished={on_form_finished}></Questions>)
  }

  return (
    <>
      <div className="bg-gray-50 h-full">
        <section className="container mx-auto flex justify-center grid grid-cols-3 pt-8 sm:pt-0">
          <div className="col-span-1"></div>
          <div className="col-span-1 flex justify-center">
            <img src="seskat.png" alt="" className="w-24 h-24"></img>
          </div>

          <div className="col-span-1 flex justify-center">
            <Menu as="div" className="col-span-1 relative inline-block text-left mt-2">
              <div>

                <Menu.Button className="justify-center grid grid-cols-2 w-12">
                  <Flag code={state.lang} className="mt-1" />
                  <ChevronDownIcon className="-mr-1  h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-1 z-10 w-14 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {supported_languages.map(lang => {
                      return (<Menu.Item key={lang}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onClick={() => { on_flag_clicked(lang_to_country(lang)) }}
                          >

                            <Flag code={lang_to_country(lang)} className="mt-1" />

                          </a>
                        )}
                      </Menu.Item>)
                    })}





                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </section>


        <div className="grid grid-cols-12 gap-4">
          <div className="md:col-span-2">
          </div>
          <div className="md:col-span-8 col-span-12">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <form className="space-y-8 divide-y divide-gray-200 ">
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

                    {current_page}

                  </div>


                </form>
              </div>
            </div>

          </div>
          <div className="md:col-span-2"></div>
        </div>

        <div className="grid grid-rows-3 grid-flow-col gap-4">
          <div className="row-span-3"></div>
          <div className="row-span-3"></div>
          <div className="row-span-3"></div>
        </div>

        <div className="grid grid-cols-12 pb-1 flex justify-center">
          <div className="col-span-2">
          </div>

          <div className="col-span-4">
            <img src="co-founded.png"></img>
          </div>

          <div className="col-span-4">
            <p className='text-xs text-black'>This project has been funded with support from the European Commission and has been approved by the Romanian National Agency. The information in this website reflects the views only of the project partners, and the Commission cannot be held responsible for any use which may be made of the information contained therein.</p>
          </div>
          <div className="col-span-2">
          </div>
        </div>
      </div>
    </>
  )
}
