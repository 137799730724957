import Radio from "./Radio";

import { answ_arr } from "./Questions";

export default function Question(props) {

    return (
        <>
            <div className="pt-6 sm:pt-5">
                <div role="group" aria-labelledby="label-question1">
                    <div className="sm:grid sm:grid-row-3 sm:items-baseline sm:gap-4">
                        <div className="flex justify-center text-center">
                            <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                id="label-question1"
                            >
                                {props.question}
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <div className="max-w-lg justify-center">
                                <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-10">


                                    {answ_arr[props.lang].map((ans, idx) => {
                                        return <Radio key={idx} id={props.id} onchange={props.onchange} checked={props.answer === idx + 1} label_id={idx} label={ans}></Radio>
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}