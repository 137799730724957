export default function Radio(props) {
    const pid = props.id.toString() + "-" + props.label;
    return (
        <>
            <div className="flex items-center">
                <input
                    id={pid}
                    name={"question" + props.id}
                    checked={props.checked}
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    onChange={props.onchange.bind(null, props.id, props.label_id)}
                />
                <label htmlFor={pid} className="ml-3 block text-sm font-medium text-gray-700">
                    {props.label}
                </label>
            </div>
        </>
    )
}