import Question from "./Question";
import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";


import { get_country_lang } from "./App";
import { db } from './firebase';

export const answ_arr = {
    de: [
        "Stimmt überhaupt nicht zu",
        "Stimmt nicht zu",
        "Stimmt zu",
        "Stimme voll und ganz zu",
    ],
    ro: [
        "Dezacord total",
        "Dezacord",
        "Sunt de acord",
        "Sunt total de acord",
    ],
    gb: [
        "Strongly disagree",
        "Disagree",
        "Agree",
        "Strongly agree",
    ],
    it: [
        "Fortemente in disaccordo",
        "In disaccordo",
        "D’accordo",
        "Fortemente d’accordo",
    ],
    es: [
        "Totalmente en desacuerdo",
        "En desacuerdo",
        "De acuerdo",
        "Muy de acuerdo"
    ],
    pl: [
        "Zupełnie się nie zgadzam",
        "Nie zgadzam się",
        "Zgadzam się",
        "Stanowczo się zgadzam",
    ]
}

const max_score = 4;

export const local_storage_key = "questions_state";

export default function Questions(props) {
    const questions = [
        //1
        {
            q: {
                de: "Wenn ich spreche, versuche ich, Details zu verwenden oder Beispiele aus dem wirklichen Leben zu geben.",
                ro: "Când vorbesc, încerc să folosesc detalii sau să dau exemple din viața reală.",
                pl: "Kiedy mówię, staram się posługiwać konkretami lub dzielić się przykładami z życia wziętymi.",
                es: "Cuando hablo, trato de utilizar información detallada o compartir ejemplos de la vida real.",
                gb: "When speaking, I try to use details or  share real-life examples.",
                it: " Quando parlo, cerco di fornire dettagli e condividere esempi di vita concreta."
            }
            , m: 1
        },
        //2
        {
            q: {
                de: "Ich fange an, langsamer, lauter und genauer zu sprechen, wenn jemand nicht zu verstehen scheint, was ich zu sagen versuche.",
                ro: "Încep să vorbesc mai lent, mai tare și mai clar, atunci când cineva pare că nu înțelege ce încerc să spun.",
                pl: "Zaczynam mówić wolniej, głośniej i dokładniej, gdy ktoś wydaje się nie rozumieć, co próbuję mu przekazać.",
                es: "Cuando alguien parece no entender lo que quiero decir, empiezo a hablar más despacio, más alto y con más precisión.",
                gb: "I begin to speak slower, louder and more accurately, when someone seems to not understand what I am trying to say.",
                it: "Quando qualcuno sembra non capire ciò che sto cercando di dire, comincio a parlare più lentamente, più forte e articolando bene le parole."
            }
            , m: 1
        },
        //3
        {
            q: {
                de: "Körpersprache, Stimmmodulation und Intonation sind sowohl für die Sprecher als auch für die Zuhörer nicht wichtig.",
                ro: "Limbajul trupului, modularea vocii și intonația sunt foarte puţin importante atât pentru vorbitori, cât și pentru public.",
                pl: "Mowa ciała, modulacja głosu i intonacja nie są znaczące ani dla mówców, ani dla słuchaczy.",
                es: "El lenguaje corporal, la modulación de la voz y la entonación no son importantes ni para los oradores ni para el público.",
                gb: "Body language, voice modulation and intonation aren’t important for both the speakers and the audience.",
                it: "Il linguaggio del corpo, la modulazione e l'intonazione della voce non sono fattori importanti da considerare tanto per il pubblico che ascolta quanto per chi sta parlando"
            }
            , m: -1
        },
        //4
        {
            q: {
                de: "Ich verwende gerne eine Fachsprache mit allen meinen Schülern.",
                ro: "Îmi place să folosesc limbajul de specialitate cu toți elevii mei.",
                pl: "W kontaktach ze wszystkimi swoimi uczniami chętnie używam języka specjalistycznego.",
                es: "Me gusta utilizar un lenguaje especializado con todo mi alumnado.",
                gb: "I like to use a specialised language with all my students.",
                it: "Mi piace usare un linguaggio specialistico con tutti i miei studenti."
            }
            , m: -1
        },
        //5
        {
            q: {
                de: "Ich halte mich für eine gute, aufmerksame und geduldige Zuhörerin. Ich bin in der Lage, die Botschaft gut zu verstehen und zu interpretieren.",
                ro: "Mă consider a fi un bun, atent și alert ascultător. Sunt capabil să înțeleg și să interpretez bine mesajele.",
                pl: "Uważam się za dobrego, uważnego i cierpliwego słuchacza. Potrafię dobrze zrozumieć i zinterpretować przekaz.",
                es: "Me considero un buen oyente que sabe prestar atención y tiene paciencia, además de ser capaz de entender e interpretar correctamente el mensaje.",
                gb: "I consider myself to be a good, alert and patient listener. I am able to understand and interpret the message well.",
                it: "Mi considero un buon ascoltatore/un buona ascoltatrice, attento/a e paziente. So comprendere e interpretare bene I messaggi."
            }
            , m: 1
        },

        //6
        {
            q: {
                de: "Ich beginne eine Schulungssitzung und stelle fest, dass meine Präsentation auf meinem Laptop nicht funktioniert. Ich weiß nicht, wie ich die Situation ohne die Präsentation bewältigen soll und kann die Schulung nicht wie geplant fortsetzen.",
                ro: "Încep o sesiune de formare și când constat că prezentarea de pe laptop nu funcționează, îmi este dificil să gestionez situația fără prezentare și nu pot continua formarea așa cum am planificat.",
                pl: "Zaraz po rozpoczęciu szkolenia, okazuje się, że moja prezentacja, którą mam na laptopie nie działa. Nie wiem jak poradzić sobie w tej sytuacji i nie jestem w stanie kontynuować szkolenia zgodnie z wcześniejszym planem.",
                es: "Empiezo una sesión formativa y cuando veo que mi presentación en el portátil no funciona, no sé cómo afrontar la situación sin la ayuda de la presentación y no puedo continuar con la formación como estaba previsto.",
                gb: "I start a training session and when I find my presentation on my laptop is not working, I don’t know how to handle the situation without the presentation and I am unable to continue with the training as planned.",
                it: "Se iniziando una sessione di formazione  scopro che la mia presentazione sul laptop non funziona, non so come gestire la situazione senza la presentazione e non sono in grado di continuare la formazione come programmato."
            }
            , m: -1
        },
        //7
        {
            q: {
                de: "6 Ihrer 10 Schulungsteilnehmer müssen die 4-stündige Sitzung, die Sie geplant hatten, wegen eines Notfalls 2 Stunden früher verlassen. Sie können die Sitzung nicht neu ansetzen. Ich bin in der Lage, mein Training (Aktivitäten/Übungen/Dynamik) trotz der fehlenden Teilnehmer neu zu gestalten.",
                ro: "6 din 10 participanti la Trainingul tau trebuie să plece cu 2 ore mai devreme din cele 4 ore pe care le-ai planificat pentru sesiunea respectivă, din cauza unei urgențe. Nu poţi reprograma sesiunea. Îţi poţi adapta Trainingul (activitati/exercitii/dinamica) pentru doar 4 persoane fără probleme.",
                pl: "6 na 10 uczestników szkolenia musi wyjść 2 godziny wcześniej z zaplanowanej przez Ciebie 4-godzinnej sesji z powodu nagłego wypadku. Nie możesz przełożyć sesji. Jestem w stanie dostosować swój trening (aktywności/ćwiczenia/dynamikę) pomimo braku osób w grupie.",
                es: "6 de mis 10 participantes de una formación que voy a impartir tienen que abandonar dos horas antes la sesión de cuatro horas que había planeado debido a una emergencia. No puedo reprogramar la sesión. A pesar de este incidente, soy capaz de readaptar mi formación (actividades/ejercicios/dinámicas) a pesar de las personas que faltan.",
                gb: "6 out of your 10 training participants have to leave 2 hours early from the 4-hour session you had planned because of an emergency. You can’t reschedule the session. I am able to readapt my training (activities/exercises/dynamics) despite the missing people.",
                it: "6 dei 10 partecipanti alla formazione devono andare via 2 ore prima dalla sessione di 4 ore programmata, a causa di una emergenza. La sessione non è riprogrammabile. Sono in grado di riadattarla nonostante le persone mancanti (a livello di attività/esercizi/dinamiche)."
            }
            , m: 1
        },
        //8
        {
            q: {
                de: "Wann immer ich eine Anregung von einem meiner Teilnehmer erhalte, berücksichtige ich sie und integriere sie in meinen Lehrgang.",
                ro: "Ori de câte ori primesc un feedback de la unul dintre participanții mei, îl iau în considerare și îl integrez în sesiunea mea de training.",
                pl: "Ilekroć dostaję sugestię od jednego z moich uczniów, biorę ją pod uwagę i włączam ją do mojego szkolenia.",
                es: "Siempre que recibo una sugerencia de uno de mis participantes, la tengo en cuenta y la integro en mi curso de formación.",
                gb: "Whenever I get a suggestion from one of my participants, I take it into account and integrate the suggestion into my training course.",
                it: "Ogni volta che ricevo un suggerimento da uno dei partecipanti, lo prendo in considerazione e lo integro nella mia formazione."
            }
            , m: 1
        },
        //9
        {
            q: {
                de: "Wenn das, was ich für die Klasse vorbereitet habe, nicht den Erwartungen der Klasse entspricht, nehme ich mir ein wenig Zeit und passe es vor Ort an, damit ich dem Wissensstand der Klasse gerecht werden kann.",
                ro: "Dacă nivelul de complexitate a ceea ce am pregătit pentru participanţi nu se potrivește cu așteptările lor, iau puţin timp și mă adaptez pe loc, astfel încât să pot întâlni nivelul lor de cunoștințe.",
                pl: "Jeśli poziom złożoności tego, co przygotowałem/am na zajęcia, nie odpowiada oczekiwaniom klasy, poświęcam chwilę czasu i rearanżuje program na miejscu, aby dostosować go do poziomu wiedzy grupy.",
                es: "Si el nivel de complejidad de lo que he preparado para la clase no se ajusta a las expectativas de la misma, me tomo un poco de tiempo y lo readapto sobre la marcha, para poder cumplir con el nivel de conocimientos de mis estudiantes.",
                gb: "If the level of complexity of what I have prepared for the class does not fit the class expectations I take a bit of time and readapt on the spot, so I can meet their level of knowledge.",
                it: "Se il livello di complessità di ciò che ho preparato per la classe non corrisponde alle aspettative, mi prendo del tempo per riadattarlo sul momento, così da poter avvicinarmi al loro livello di conoscenza."
            }
            , m: 1
        },
        //10
        {
            q: {
                de: "Die Idee, die ich entwickelt habe, wird von den Teilnehmern demontiert, sie sagen, sie sei nicht für ihr Arbeitsumfeld geeignet. Die Sitzung endet mit einem guten Ergebnis und alle sind zufrieden. Ich gehe nach Hause, denke nach und untersuche die Argumente, die sie mir genannt haben. In der nächsten Sitzung gehe ich mit einem neuen Ansatz an die Sache heran, der die Herausforderungen der Teilnehmer berücksichtigt.",
                ro: "Ideile pe care le prezint sunt demontate de participanți, ei spun că nu sunt potrivite pentru mediul de lucru în care ei lucreză. Sesiunea se încheie într-o notă bună, toată lumea este fericită. Merg acasă și mă gândesc și cercetez argumentele pe care mi le-au dat. Pentru următoarea sesiune, merg cu o abordare nouă care ia în considerare provocările colegilor.",
                pl: "Pomysł, który opracowałem/am nie spotyka się z akceptacją grupy, mówią, że nie pasuje do ich środowiska pracy. Mimo to sesja kończy się pomyślnie i wszyscy są zadowoleni. Wracam do domu, zastanawiam się i rozważam argumenty, które padły. Na następną sesję wybieram się ze świeżym podejściem, które uwzględnia ich argumenty.",
                es: "La idea que he desarrollado es desmontada por los participantes ya que dicen que no se adapta a su entorno de trabajo. La sesión termina de buena forma y todos están contentos. Me voy a casa y pienso e investigo los argumentos que me han dado. Para la siguiente sesión, planteo un nuevo enfoque que tenga en cuenta sus retos.",
                gb: "The idea that I developed is being dismantled by the participants, they say it is not suited for their working environment. The session ends on a good note and everyone is happy. I go home and I think and research the arguments they gave me. For the next session, I go with a fresh approach that takes into account their challenges.",
                it: "L’idea che ho sviluppato viene smontata dai partecipanti che dicono che non è adatta al loro ambiente di lavoro. Torno a casa, rifletto e cerco I temi che mi hanno fornito. Così, per la sessione successive, propongo un approccio nuovo che tenga conto di essi."
            }
            , m: 1
        },

        //11
        {
            q: {
                de: "Ich bin mir meiner Körpersprache bewusst und weiß, was ich durch mein Handeln kommuniziere, wenn ich einen Konflikt im Klassenzimmer bewältigen muss.",
                ro: "Sunt conștient de limbajul corporal și ce comunic prin acțiunile mele atunci când sunt nevoit să gestionez un conflict în sala de curs.",
                pl: "Kiedy jestem zmuszony/a do rozwiązania konfliktu w klasie, jestem świadomy/a swojej mowy ciała i tego, co komunikuję poprzez moje działania.",
                es: "Soy consciente de mi lenguaje corporal y de lo que comunico con mis acciones cuando tengo que gestionar un conflicto en el aula.",
                gb: "I am aware of my body language and what I am communicating through actions when I need to manage a conflict in the classroom.",
                it: "Sono consapevole del mio linguaggio corporeo e di ciò che comunico attraverso le azioni quando devo gestire un conflitto in classe."
            }
            , m: 1
        },
        //12
        {
            q: {
                de: "Um einen Konflikt zu beenden, ziehe ich es vor, offen zu reden und eine Lösung zu finden, die alle Beteiligten zufrieden stellt, auch wenn das bedeutet, dass ich auf einen Teil meines geplanten Materials verzichten muss.",
                ro: "Ca sa sting un conflict, prefer să vorbesc în mod deschis şi să găsesc o soluție care să mulțumească pe toată lumea, chiar dacă asta înseamnă să renunț la unele dintre activităţile pe care le-am planificat.",
                pl: "Aby zakończyć konflikt, wolę otwarcie rozmawiać i znaleźć rozwiązanie, które zadowoli wszystkich zaangażowanych, nawet jeśli oznacza to rezygnację z części zaplanowanego materiału.",
                es: "Para poner fin a un conflicto, prefiero hablar abiertamente y encontrar una solución que satisfaga a todos los implicados, incluso si esto significa renunciar a parte de mis planteamientos e ideas.",
                gb: "To end a conflict, I prefer to talk openly and find a solution that satisfies everyone involved even if this means giving up on some of my planned material.",
                it: "Per mettere fine ad un conflitto, preferisco parlare apertamente e trovare una soluzione che soddisfi tutte le persone coinvolte, anche se ciò significa lasciare parzialmente da parte quanto precedentemente pianificato, in parte."
            }
            , m: 1
        },
        //13
        {
            q: {
                de: "Ich bin daran interessiert, die Meinungen und Perspektiven meiner Schüler zu hören, wenn es zu einem Missverständnis kommt.",
                ro: "Sunt interesat să aud opiniile și perspectiva participanţilor atunci când apare o neînțelegere.",
                pl: "Jestem zainteresowany/a poznaniem opinii i punktu widzenia moich uczniów w przypadku pojawiających sie nieporozumień.",
                es: "Me interesa escuchar las opiniones y perspectivas de mis alumnos cuando se produce un malentendido.",
                gb: "I am interested in hearing the opinions and perspectives of my students when a misunderstanding occurs.",
                it: "Mi interessa ascoltare le opinioni e I punti di vista dei miei studenti quando si verifica una incomprensione."
            }
            , m: 1
        },
        //14
        {
            q: {
                de: "Wenn ein Konflikt auftritt, betrachte ich diesen Moment als Lernchance und stelle ihn den Schülern als solche vor.",
                ro: "Atunci când apare un conflict, consider momentul o oportunitate de învățare și îl prezint așa şi participanţilor.",
                pl: "Kiedy pojawia się konflikt, myślę o tej sytuacji jako o możliwości nauczenia się czegoś i w ten sposób przedstawiam sytuację moim uczniom.",
                es: "Cuando surge un conflicto, pienso en ese momento como una oportunidad de aprendizaje y lo presento como tal a los alumnos.",
                gb: "When a conflict arises, I think of the moment as a learning opportunity and present it as such to students.",
                it: "Quando emerge un conflitto, lo considero un’opportunità di apprendimento e lo presento come tale agli studenti."
            }
            , m: 1
        },
        //15
        {
            q: {
                de: "Wenn mir im Unterricht ein Fehler unterläuft und ein Schüler mich darauf hinweist, bin ich nicht in der Lage, meine emotionale Reaktion zu beobachten und zu kontrollieren und entschuldige mich normalerweise nicht.",
                ro: "Atunci când fac o greșeală în timpul lecțiilor și un elev mi-o semnalează, nu reușesc să-mi observ și să-mi gestionez reacția emoționalam o reacţie emoţională.",
                pl: "Podczas lekcji popełniam błąd, który zostaje zauważony przez jednego z uczniów. W tej sytuacji nie jestem w stanie kontrolować swoich emocji i nad nimi zapanować, zazwyczaj nie przepraszam.",
                es: "Cuando cometo un error durante mis clases y un/a alumno/a me lo señala, no soy capaz de observar y controlar mi reacción emocional y no suelo disculparme.",
                gb: "When I make a mistake during my lessons and a student points it out, I am not able to observe and manage my emotional reaction and I don’t usually apologise.",
                it: "Quando commetto un errore durante le lezioni e uno studente me lo fa notare, non sono in grado di osservare e gestire la mia reazione emotiva e di solito non chiedo scusa."
            }
            , m: -1
        },

        //16
        {
            q: {
                de: "Ich erkenne, wie die Emotionen, die ich mit in den Unterricht bringe, das Lernumfeld beeinflussen.",
                ro: "Înţeleg cum emoţiile pe care le aduc în sala de curs afectează spaţiul de învăţare. ",
                pl: "Rozpoznaję, jak emocje, które wnoszę do klasy, wpływają na środowisko uczenia się.",
                es: "Soy consciente de que las emociones que traslado al aula afectan al entorno de aprendizaje.",
                gb: "I recognise how the emotions I bring to the classroom affect the learning environment.",
                it: "Riconosco come le emozioni che porto in classe influenzano l’ambiente di apprendimento."
            }
            , m: 1
        },
        //17
        {
            q: {
                de: "Ich fördere eine Kultur der Empathie und des Mitgefühls in meinem Klassenzimmer.",
                ro: "Promovez o cultură a empatiei şi compasiunii în sala mea de curs. ",
                pl: "Promuję kulturę empatii i współczucia w mojej klasie.",
                es: "Promuevo una cultura de empatía y compasión en mi aula.",
                gb: "I promote a culture of empathy and compassion in my classroom.",
                it: "Promuovo una cultura dell’empatia e della compassione nella mia classe."
            }
            , m: 1
        },
        //18
        {
            q: {
                de: "Ich kann Teambuilding-Aktivitäten / Eisbrecher-Aktivitäten effektiv einsetzen, um die Beziehungen zwischen den Lernenden zu fördern.",
                ro: "Pot să folosesc activităţi de formare de echipă şi ice-brakers pentru a construi relaţia dintre participanţii mei. ",
                pl: "Potrafię skutecznie wykorzystywać aktywności mające na celu budowanie zespołu / zajęcia przełamujące lody, aby polepszyć relacje pomiędzy moimi uczniami.",
                es: "Soy capaz de utilizar eficazmente ejercicios de trabajo en equipo y actividades para romper el hielo con el fin de mejorar la relación entre mis alumnos.",
                gb: "I can effectively use team building activities / ice-breaker activities to help build my learners’ relationships.",
                it: "Sono in grado di utilizzare con efficacia le attività di team building /attività rompighiaccio per aiutare a costruire relazioni fra i miei studenti."
            }
            , m: 1
        },
        //19
        {
            q: {
                de: "Meine Lernenden fühlen sich unwohl, wenn sie andere Meinungen äußern und anhören.",
                ro: "Cursanții mei se simt incomod în timp ce își exprimă și ascultă opinii diferite.",
                pl: "Moi uczniowie czują się niekomfortowo podczas wyrażania i słuchania różnego rodzaju opinii. ",
                es: "Mi alumnado se siente incómodo cuando expresa y escucha opiniones diferentes.",
                gb: "My learners feel uncomfortable whilst expressing and listening to different opinions.",
                it: "I miei studenti si sentono a disagio mentre esprimono e ascoltano opinioni diverse."
            }
            , m: -1
        },
        //20
        {
            q: {
                de: "Ich kann faire Entscheidungen treffen, die den Bedürfnissen aller Lernenden Rechnung tragen.",
                ro: "Pot să iau decizii corecte luând în considerare nevoie tuturor participanților din sala mea de curs.",
                pl: "Potrafię podejmować sprawiedliwe decyzje uwzględniające potrzeby wszystkich uczniów.",
                es: "Puedo tomar decisiones justas que tengan en cuenta las necesidades de todos el alumnado.",
                gb: "I can make fair decisions that take into consideration the needs of all learners.",
                it: "So prendere decisioni corrette che considerino le esigenze di tutti gli studenti."
            }
            , m: 1
        },

        //21
        {
            q: {
                de: "Wenn eine Unterrichtsstunde nicht wie geplant verläuft, lerne ich daraus und ändere meine Vorgehensweise beim nächsten Mal.",
                ro: "Când un curs sau atelier nu funcţionează cum am plănuit, învăţ din ele şi schimb abordarea pentru data viitoare.",
                pl: "Kiedy lekcja nie idzie zgodnie z planem, wyciągam z niej wnioski i następnym razem zmieniam podejście.",
                es: "Cuando una lección no sale como estaba prevista, aprendo de ella y cambio mi enfoque la próxima vez.",
                gb: "When a lesson doesn’t go as planned, I learn from it and change my approach next time.",
                it: "Quando una lezione non va come previsto, ne traggo insegnamento e cambio il mio approccio la volta successiva."
            }
            , m: 1
        },
        //22
        {
            q: {
                de: "In einer Krise oder einer chaotischen Situation kann ich mich nicht beruhigen und mich nicht auf sinnvolle Maßnahmen konzentrieren.",
                ro: "Într-o situație de criză sau haotică, sunt agitat şi îmi este greu să mă concentrez pe luarea unor măsuri utile.",
                pl: "W sytuacji kryzysowej lub chaotycznej mam problem z uspokojeniem się i skupieniem się na podejmowaniu racjonalnych, pożytecznych działań.",
                es: "En una crisis o una situación caótica, no puedo calmarme y centrarme en tomar las medidas oportunas",
                gb: "In a crisis or a chaotic situation, I cannot calm myself and focus on taking useful actions.",
                it: "In una situazione di crisi o in una situazione caotica, non riesco a calmarmi e concentrarmi sull'intraprendere azioni utili."
            }
            , m: -1
        },
        //23
        {
            q: {
                de: "Wenn sich aufgrund äußerer Umstände ein grundlegender Wandel vollzieht, finde ich es schwierig, mich auf neue Denkweisen einzulassen.",
                ro: "Atunci când există o schimbare fundamentală datorată unor circumstanțe externe, mi se pare dificil să mă adaptez la noi moduri de gândire.",
                pl: "Kiedy zachodzą duże zmiany spowodowane nieprzewidzianymi okolicznościami zewnętrznymi, mam problem ze zmianą sposobu myślenia/działania.",
                es: "Cuando se produce un cambio fundamental debido a circunstancias externas, me resulta difícil adoptar nuevas formas de pensar.",
                gb: "When there is a fundamental change due to external circumstances, I find it difficult to come around to new ways of thinking.",
                it: "Quando mi trovo in una situazione in cui avviene un cambiamento importante dovuto a circostanze esterne, trovo difficile costruire nuovi modi di pensare."
            }
            , m: -1
        },
        //24
        {
            q: {
                de: "Um Hilfe zu bitten ist kein Zeichen von Schwäche, also bitte ich normalerweise meine Kollegen um Hilfe.",
                ro: "A cere ajutor este un semn de slăbiciune.",
                pl: "Proszenie o pomoc jest oznaką słabości, dlatego tego nie robię.",
                es: "Pedir ayuda es un signo de debilidad, así que prefiero no pedir ayuda a mis compañeros/as.",
                gb: "Asking for assistance is a sign of weakness, so I don’t ask my colleagues for help.",
                it: "Chiedere assistenza/supporto è un segno di debolezza, quindi non chiedo aiuto ai miei colleghi."
            }
            , m: -1
        },
        //25
        {
            q: {
                de: "Ich verfüge über Strategien, um mit stressigen Situationen, die durch schwierige Schüler verursacht werden, umzugehen und den Rest der Klasse erfolgreich zu managen.",
                ro: "Am strategii bine stabilite de gestionare a participanţilor dificili şi integrarea lor în restul grupului.",
                pl: "Posiadam strategie radzenia sobie ze stresującymi sytuacjami spowodowanymi przez trudnych uczniów, a tym samym skutecznego zarządzania resztą klasy.",
                es: "Dispongo de estrategias para hacer frente a situaciones de estrés provocadas por alumnos difíciles y para gestionar con éxito el resto de la clase.",
                gb: "I have strategies in place for dealing with stressful situations caused by difficult students and for managing the rest of the class successfully.",
                it: "Ho delle strategie per affrontare situazioni di stress causate da studenti difficili per gestire con successo il resto della classe."
            }
            , m: 1
        },

        //26
        {
            q: {
                de: "Wenn ich aufgrund persönlicher Probleme einen schlechten Tag habe, kann ich mich trotzdem auf das Training und die Teilnehmer konzentrieren, ohne dass sie es merken.",
                ro: "Dacă am o zi dificilă din cauza unor probleme personale, mă pot focusa pe training şi pe participanţi, fără ca ei să realizeze că nu sunt în apele mele.",
                pl: "Jeśli mam zły dzień z powodu problemów osobistych, nadal jestem w stanie skupić się na szkoleniu i uczestnikach, w taki sposób, aby nie zauważyli żadnej różnicy.",
                es: "A pesar de haber tenido un mal día debido a problemas personales, puedo concentrarme en la formación y en los participantes, sin que ellos se den cuenta.",
                gb: "If I am having a bad day because of personal problems, I can still focus on the training and the participants, without them realising it.",
                it: "Se ho una cattiva giornata a causa di problemi personali, posso concentrarmi comunque sulla formazione e sui partecipanti, senza che quindi questi se ne accorgano."
            }
            , m: 1
        },
        //27
        {
            q: {
                de: "Ich finde es schwierig, mit dem Training fortzufahren, wenn die Teilnehmer herausforderndes Verhalten zeigen oder nicht aufpassen.",
                ro: "Mi se pare dificil să continui formarea atunci când participanții manifestă un comportament dificil sau nu sunt atenți.",
                pl: "Trudno jest mi kontynuować szkolenie, gdy uczestnicy nie właściwie się zachowują lub nie przywiązują uwagi do prowadzonych zajęć.",
                es: "Me resulta difícil seguir con la formación cuando los/las participantes muestran un comportamiento difícil o no prestan atención.",
                gb: "I find it difficult to go on with the training when participants display challenging behaviour or are not paying attention.",
                it: "Trovo difficile continuare la formazione quando i partecipanti mostrano un comportamento provocatorio o non prestano attenzione."
            }
            , m: -1
        },
        //28
        {
            q: {
                de: "Wenn ich merke, dass die Teilnehmer Schwierigkeiten haben, kann ich sie ansprechen, anstatt mich entmutigen zu lassen.",
                ro: "Când observ participanţi care se chinuie să înţeleagă, sunt capabil să îi implic în loc să mă simt descurajat.",
                pl: "Kiedy zauważam, że uczestnicy mają trudności z materiałem, jestem w stanie ich bardziej zaangażować, aby uniknąć uczucia zniechęcenia.",
                es: "Cuando me doy cuenta de que los participantes tienen dificultades, soy capaz de involucrarlos en lugar de sentirme desanimado/a.",
                gb: "When I notice participants who are struggling, I am able to engage them instead of feeling discouraged.",
                it: "Quando mi accorgo che I partecipanti sono in difficoltà, riesco a coinvolgerli senza perdere fiducia."
            }
            , m: 1
        },
        //29
        {
            q: {
                de: "Ich kann keine Teilnehmer in die Ausbildung einbeziehen, deren Ideen und Verhalten das komplette Gegenteil von meinen sind.",
                ro: "Nu pot include în formare participanți ale căror idei și comportament sunt complet opuse celor ale mele.",
                pl: "Mam problem z włączeniem w szkolenie uczestników, których pomysły i zachowania są całkowitym przeciwieństwem moich.",
                es: "No puedo incluir en la formación a participantes cuyas ideas y comportamiento son totalmente opuestos a los míos.",
                gb: "I cannot include in the training participants whose ideas and behaviour are the complete opposite of mine.",
                it: "Faccio fatica ad includere nella mia proposta formativa partecipanti le cui idee e comportamenti sono completamente opposti ai miei."
            }
            , m: -1
        },
        //30
        {
            q: {
                de: "Wenn Teilnehmer im Unterricht ein Problem haben, bin ich bereit, ihnen zuzuhören, ohne dass meine persönlichen Gefühle dazwischenfunken.",
                ro: "Dacă participanţii se confruntă cu o situaţie dificilă, sunt deschis să ascult fără să las propriile emoţii să interfereze.",
                pl: "Jeśli uczestnicy napotkają jakiś problem na zajęciach, jestem otwarty na ich wysłuchanie bez angażowania moich własnych odczuć.",
                es: "Si los participantes se enfrentan a un problema en clase, estoy abierto a escucharlos sin que mis sentimientos personales interfieran.",
                gb: "If participants happen to face an issue in class, I am open to listen to them without my personal feelings interfering.",
                it: "Se I partecipanti si trovano ad affrontare un problema in classe, sono disponibile a ascoltarli senza interferenza dei miei sentimenti personali."
            }
            , m: 1
        },

        //31
        {
            q: {
                de: "Ich denke, dass es nicht notwendig ist, zusätzliche Zeit damit zu verbringen, mit meiner Gruppe darüber zu sprechen, was von ihr verlangt wird, was gut läuft und was verbessert werden muss, sie sollten es bereits wissen.",
                ro: "Cred că nu este necesar să petrec timp suplimentar vorbind cu grupul meu despre ceea ce se cere de la ei, despre ceea ce merge bine și ceea ce trebuie îmbunătățit, ei ar trebui să știe deja acest lucru.",
                pl: "Uważam, że nie trzeba poświęcać dodatkowego czasu na rozmowy z grupą o tym, czego się od nich wymaga, co idzie dobrze, a co wymaga poprawy, oni powinni już to wiedzieć.",
                es: "Creo que no es necesario dedicar más tiempo a hablar con mi grupo sobre lo que se les pide, lo que va bien y lo que hay que mejorar, ya deberían saberlo.",
                gb: "I think it isn’t necessary to spend extra time talking with my group about what is required from them, what is going well and what needs improving, they should already know it.",
                it: "Penso che non sia necessario dedicare del tempo per parlare con il mio gruppo delle reciproche aspettative, di cosa sta andando bene e di cosa potrebbe essere migliorato. Il gruppo dovrebbe già saperlo."
            }
            , m: -1
        },
        //32
        {
            q: {
                de: "Ich versuche, die Menschen in meiner Gruppe zu motivieren, indem ich meinen Motivationsansatz auf die Bedürfnisse jedes Einzelnen bestimmt, um sie bei der Erreichung ihrer persönlichen Ziele zu unterstützen.",
                ro: "Încerc să ii motivez pe oamenii din grupul meu adaptand abordarea mea privind motivarea, pentru a se potrivi nevoilor fiecărui individ în parte, pentru a-i sprijini în atingerea obiectivelor personale.",
                pl: "Staram się motywować ludzi w mojej grupie, dostosowując swoje podejście do indywidualnych potrzeb, aby wspierać ich w osiąganiu osobistych celów.",
                es: "Intento motivar a las personas de mi grupo adaptando mi propio enfoque sobre la motivación a las necesidades de cada individuo para ayudarles a alcanzar sus objetivos personales.",
                gb: "I try to motivate people within my group by tailoring my approach to motivation to match each individual's needs in order to support them in achieving their personal goals.",
                it: "Cerco di motivare le persone del mio gruppo adattando il mio approccio alla motivazione per incontrare I bisogni di ognuno al fine di fornire supporto nel raggiungimento degli obiettivi."
            }
            , m: 1
        },
        //33
        {
            q: {
                de: "Ich sorge dafür, dass sich jedes Gruppenmitglied wichtig fühlt, aber ich kenne nicht alle Namen, und ich bin nicht immer in der Lage, ein paar Fakten über jeden Einzelnen zu erzählen.",
                ro: "Mă asigur că fiecare membru al grupului se simte important, dar nu știu numele tuturor și nu sunt întotdeauna în măsură să împărtășesc câteva date despre fiecare persoană.",
                pl: "Dbam o to, aby każdy członek grupy czuł się ważny, ale nie znam imion wszystkich osób i nie zawsze jestem w stanie podzielić się kilkoma faktami o każdej osobie.",
                es: "Me aseguro de que todos los miembros del grupo se sientan importantes, pero no sé los nombres de todos y no siempre puedo compartir algunos datos sobre cada individuo.",
                gb: "I make sure each group member feels important but I don’t know everyone’s names and I am not always able to share a few facts about every individual.",
                it: "Mi assicuro sempre che ogni membro del gruppo si senta importante, ma non conosco i nomi di tutti e non sono sempre in grado di condividere alcune informazioni su ogni individuo."
            }
            , m: -1
        },
        //34
        {
            q: {
                de: "Ich bin in der Lage, die meisten der für meine Gruppe gesetzten Bildungsziele zu erreichen, indem ich den Grad ihres Engagements und das richtige Verhältnis zwischen Arbeits- und Entspannungsmodus steuere.",
                ro: "Sunt capabil de a atinge cele mai multe dintre obiectivele de instruire setate pentru grupul meu prin gestionarea nivelului lor de implicare și proporția dintre timpul de lucru și de relaxare.",
                pl: "Potrafię osiągnąć większość celów edukacyjnych stawianych mojej grupie, zarządzając ich poziomem zaangażowania i utrzymywać odpowiednie proporcje pomiędzy trybem pracy i relaksu.",
                es: "Soy capaz de alcanzar la mayoría de los objetivos educativos fijados para mi grupo gestionando su nivel de compromiso y la proporción adecuada entre el modo de trabajo y el empleado para el descanso.",
                gb: "I am able to achieve most of the educational goals set for my group by managing their level of engagement and the right proportion between work and relax mode.",
                it: "So raggiungere la maggior parte degli obiettivi educativi stabiliti per il mio gruppo gestendo il loro livello di impegno e la giusta proporzione fra lavoro e modalità ricreativa."
            }
            , m: 1
        },
        //35
        {
            q: {
                de: "Ich bin in der Lage, die Paar- und Gruppenarbeit zu optimieren, um den Schülern die Möglichkeit zu geben, sich zu präsentieren.",
                ro: "Am capacitatea de a optimizăm timpul de lucrul individual, în perechi și în grupuri pentru a da elevilor ocazia să performeze.",
                pl: "Potrafię zoptymalizować pracę w parach i grupach, aby dać uczniom szansę na wykazanie się.",
                es: "Soy capaz de potenciar el trabajo en parejas y en grupos para que los alumnos tengan la oportunidad de participar e interactuar.",
                gb: "I am able to optimise the pair and group work to give students a chance to perform.",
                it: "So ottimizzare il lavoro a coppie e in gruppi per dare agli studenti la possibilità di agire al meglio."
            }
            , m: 1
        },

        //36
        {
            q: {
                de: "Ich bin mir nicht bewusst, welche Dinge mich motivieren oder demotivieren können und wie ich sie nutzen kann.",
                ro: "Nu sunt conștient de lucrurile care mă pot motiva sau demotiva și cum să le folosesc.",
                pl: "Nie mam świadomości, jakie rzeczy mogą mnie motywować lub demotywować i jak je wykorzystać.",
                es: "No sé qué tipo de cosas pueden motivarme o desmotivarme y cómo utilizarlas.",
                gb: "I am not aware of the things that can motivate or demotivate me and how to use them.",
                it: "Non sono consapevole delle cose che possono motivarmi o demotivarmi e di come posso usarle nella mia pratica professionale."
            }
            , m: -1
        },
        //37
        {
            q: {
                de: "Ich habe immer das Ziel meiner Sitzung vor Augen und passe meinen Stil und Inhalt so an, dass ich das Ziel erreichen kann. Ich verliere nie den Fokus auf das, was wichtig ist.",
                ro: "Întotdeauna ţin obiectivul unei sesiuni în minte și adaptez stilul și conținutul pentru a atinge acel obiectivl. Nu pierd niciodată focusul asupra ceea ce contează.",
                pl: "Zawsze mam na uwadze cel mojej sesji i dostosowuję swój styl i treść, aby osiągnąć zamierzony cel. Nigdy nie tracę koncentracji na tym, co ważne.",
                es: "Siempre tengo en mente el objetivo de mi sesión y adapto mi estilo y contenido para poder alcanzar dicho objetivo. Nunca pierdo de vista lo que es importante.",
                gb: "I always have the objective of my session in mind and I adapt my style and content so I can reach the objective. I never lose focus of what matters.",
                it: "Tengo sempre a mente l’obiettivo delle sessioni e adatto I contenuti in modo da raggiungerlo. Non perdo di vista quello che conta."
            }
            , m: 1
        },
        //38
        {
            q: {
                de: "Es fällt mir nicht leicht, andere von den Vorteilen der von mir vorbereiteten Sitzungen zu überzeugen.",
                ro: "Nu reușesc să-i conving cu ușurință pe ceilalți de beneficiile sesiunilor pe care le pregătesc.",
                pl: "Nie jest mi łatwo przekonać innych o korzyściach płynących z przygotowywanych przeze mnie sesji.",
                es: "No me resulta fácil convencer a las demás personas sobre los ventajas de las sesiones que preparo.",
                gb: "I cannot easily convince others about the benefits of the sessions I prepare.",
                it: "Non riesco facilmente a convincere gli altri dei benefici/vantaggi relativi alle sessioni che preparo e conduco."
            }
            , m: -1
        },
        //39
        {
            q: {
                de: "Ich habe Gewohnheiten, die mich motivieren und die ich anderen Menschen beibringe, damit auch sie ihre Motivation verbessern können.",
                ro: "Am obiceiuri care mă motivează și pe care le predau altor oameni astfel încât și ei să își poată îmbunătăți motivația.",
                pl: "Mam nawyki, które mnie motywują i które staram się przekazać innym, aby oni również mogli poprawić swoją motywację.",
                es: "Tengo hábitos que me motivan y que enseño a otras personas para que también puedan mejorar su motivación.",
                gb: "I have habits that keep me motivated and that I teach to other people so they can also improve their motivation.",
                it: "Ho abitudini che mantengono alta la mia motivazione e le insegno alle altre persone affinché possano migliorare anche la loro motivazione."
            }
            , m: 1
        },
        //40
        {
            q: {
                de: "Ich gönne mir keine Pause, wenn ich mich unmotiviert fühle. Ich weiß nicht, ob ich wieder auf die Beine komme oder ob ich eine Pause brauche.",
                ro: "Nu-mi permit o pauză dacă mă simt nemotivat. Nu știu dacă mă voi pune pe picioare sau dacă am nevoie de odihnă.",
                pl: "Nie pozwalam sobie na przerwę, jeśli czuję, że brakuje mi motywacji. Nie wiem, czy stanę na nogi lub czy potrzebuję odpoczynku.",
                es: "No me permito un descanso si me siento desmotivado/a. No sé si me recuperaré o si necesito descansar.",
                gb: "I don’t allow myself a break if I am feeling unmotivated. I don’t know if I will get back on my feet or if I need some rest.",
                it: "Non mi concedo mai una pausa se mi sento demotivato. Non so se mi rimetterò in attività o se avrò bisogno di un po' di riposo."
            }
            , m: -1
        },

        //41
        {
            q: {
                de: "Wenn ein Lernender mich oder den Inhalt, den ich im Unterricht vermittle, kritisiert, bin ich nicht in der Lage, ruhig und bestimmt zu antworten, da ich mir meines eigenen Wertes als Ausbilder nicht bewusst bin.",
                ro: "Dacă un cursant mă critică pe mine sau conținutul pe care îl transmit în clasă, nu sunt capabil să răspund cu calm și fermitate, fiind inconștient de propria mea valoare ca formator.",
                pl: "Jeśli uczeń krytykuje mnie lub treści, które przekazuję podczas zajęć, nie jestem w stanie zareagować spokojnie i stanowczo, jestem nieświadomy własnej wartości jako trenera.",
                es: "Si un/a alumno/a me critica a mí o el contenido que transmito en clase, no soy capaz de responder con calma y firmeza, al no ser consciente de mi propio valor como formador/a.",
                gb: "If a learner criticises me or the content that I convey in class, I am not able to answer calmly and firmly, being unconscious of my own value as a trainer.",
                it: "Se uno studente critica me o il contenuto che presento in classe non sono in grado di rispondere con calma e fermezza, in quanto non sono consapevole del mio valore come formatore."
            }
            , m: -1
        },
        //42
        {
            q: {
                de: "Ich bin in der Lage, klare Grenzen zu setzen, wenn es um die Disziplin in der Klasse geht.",
                ro: "Sunt capabil să setez limite clare când vine vorba despre respectul din sala mea de curs.",
                pl: "Potrafię wyznaczyć jasne granice, jeśli chodzi o dyscyplinę na zajęciach.",
                es: "Soy capaz de establecer límites claros cuando quiero establecer disciplina en clase.",
                gb: "I am able to set clear boundaries when it comes to discipline in class.",
                it: "Sono in grado di stabilire chiari limiti  quando si tratta di disciplina, in classe."
            }
            , m: 1
        },
        //43
        {
            q: {
                de: "Wenn ich ein Thema unterrichte, das mir neu ist, fühle ich mich unsicher, es zu vermitteln.",
                ro: "Când predau un subiect nou, nu mă simt încrezător în predarea acestuia.",
                pl: "Czuję się niepewnie, kiedy prowadzę zajęcia na temat, który jest dla mnie nowy.",
                es: "Cuando enseño un tema que es nuevo para mí, me siento inseguro/a a la hora de impartirlo.",
                gb: "When I am teaching a topic that is new to me, I feel unconfident in delivering it.",
                it: "Quando insegno/trasmetto un argomento che per me è nuovo, non mi sento sicuro nel trasmetterlo."
            }
            , m: -1
        },
        //44
        {
            q: {
                de: "Ich bin stolz auf meinen Studien- und Arbeitsweg und weiß, dass ich in jeder einzelnen Ausbildung, die ich durchführe, mein Bestes gebe.",
                ro: "Sunt mândru de experienţa şi munca mea şi ştiu că dau ce am mai bun în fiecare sesiune de training pe care o livrez.",
                pl: "Jestem dumny ze swojej ścieżki nauki i pracy i wiem, że daję z siebie wszystko na każdych zajęciach, które prowadzę.",
                es: "Me siento orgulloso de mi trayectoria académica y profesional y sé que doy lo mejor de mí en cada una de las formaciones que imparto.",
                gb: "I am proud of my study and work path and I know I am giving my best in every single training I deliver.",
                it: "Sono orgoglioso/a del mio percorso di studi e so dare il meglio di me in ogni formazione."
            }
            , m: 1
        },
        //45
        {
            q: {
                de: "Wenn es in der Klasse Meinungsverschiedenheiten und Konflikte gibt, fühle ich mich unsicher und weiß nicht, wie ich mit der Situation umgehen soll.",
                ro: "Dacă apar dezacorduri și conflicte în clasă, mă simt lipsit de încredere și nu știu cum să gestionez situația. ",
                pl: "Kiedy w klasie dochodzi do nieporozumień lub konfliktów, brak mi pewności siebie i nie wiem, jak poradzić sobie z tą sytuacją. ",
                es: "Si hay desacuerdos y conflictos en clase, me siento poco seguro/a y no sé cómo manejar la situación. ",
                gb: "If there are disagreements and conflicts in class, I feel unconfident and I don’t know how to handle the situation. ",
                it: "Se ci sono disaccordi e conflitti in classe, mi sento insicuro e non so come gestire la situazione."
            }
            , m: -1
        },

        //46
        {
            q: {
                de: "Ich kann sagen, dass ich mir, wenn ich während eines Trainingsprozesses Frustration erlebe, immer bewusst bin, wie ich mich in diesem Moment fühle.",
                ro: "Pot spune că în timpul trainingului, atunci când am frustrări, sunt conștient de sentimentele mele în acel moment.",
                pl: "Mogę śmiało przyznać, że kiedy doświadczam frustracji podczas szkolenia, zawsze jestem świadomy, jak się czuję w tym momencie.",
                es: "Cada vez que siento frustración durante un proceso de formación, soy consciente de cómo me siento en ese momento.",
                gb: "I can say that when I experience frustration during a training process, I am always aware of how I am feeling in that moment.",
                it: "Posso dire che quando provo frustrazione durante un processo formativo, sono sempre consapevole del mio stato d’animo del momento."
            }
            , m: 1
        },
        //47
        {
            q: {
                de: "Wenn ich erkenne, dass ich frustriert bin, fällt es mir schwer, meine Einstellung und Stimmung zu ändern.",
                ro: "Atunci când recunosc că mă simt frustrat, îmi este greu să-mi schimb atitudinea și starea de spirit. ",
                pl: "Kiedy zauważam, że czuję się sfrustrowany, trudno mi zmienić swoje nastawienie i nastrój. ",
                es: "Cuando reconozco que me siento frustrado/a, me cuesta cambiar de actitud y de humor. ",
                gb: "When I recognise that I am feeling frustrated, I find it difficult to change my attitude and mood. ",
                it: "Quando sono consapevole di sentirmi frustrato, trovo difficile cambiare il mio atteggiamento e/o il mio umore."
            }
            , m: -1
        },
        //48
        {
            q: {
                de: "Ich erlebe Frustration als einen automatischen Prozess, den ich nicht kontrollieren kann.",
                ro: "Resimt frustrarea ca pe un proces automat pe care nu îl pot controla.",
                pl: "Frustrację przeżywam jako automatyczny proces, którego nie potrafię kontrolować.",
                es: "Experimento la frustración como un proceso automático que no puedo controlar.",
                gb: "I experience frustration as an automatic process that I cannot control.",
                it: "Sperimento la frustrazione come un processo automatico che non riesco/posso controllare."
            }
            , m: -1
        },
        //49
        {
            q: {
                de: "Manchmal nutze ich beim Training Frustration als Trainingsstrategie.",
                ro: "Uneori în timpul unei sesiuni de training, folosesc frustrarea ca o strategie de training.",
                pl: "Czasami podczas szkolenia wykorzystuję frustrację jako strategię treningową.",
                es: "A veces, durante un entrenamiento, utilizo la frustración como estrategia de entrenamiento.",
                gb: "Sometimes during a training, I use frustration as a training strategy.",
                it: "A volte, durante una formazione, vedo la frustrazione come una strategia formativa."
            }
            , m: 1
        },
        //50
        {
            q: {
                de: "Ich kenne einige spezifische Strategien, die ich zur Frustrationsbewältigung einsetzen kann, insbesondere beim Training.",
                ro: "Ştiu câteva strategii specifice de gestionare al frustrării, în special în timpul trainingului.",
                pl: "Znam kilka konkretnych strategii, które potrafię wykorzystać do radzenia sobie z frustracją, zwłaszcza podczas szkolenia.",
                es: "Conozco algunas estrategias específicas que me permiten gestionar la frustración, especialmente durante el desarrollo de una sesión.",
                gb: "I know some specific strategies that I am able to use for managing frustration, especially during training.",
                it: "Conosco alcune strategie specifiche che so usare per la gestione della frustrazione, soprattutto durante le formazioni."
            }
            , m: 1
        },

        //51
        {
            q: {
                de: "Ich kenne die vier Lernstile und die Merkmale der einzelnen Stile.",
                ro: "Cunosc şi înţeleg cele patru stiluri de învăţare şi caracteristicile fiecărui stil.",
                pl: "Znam wszystkie cztery główne style uczenia się i charakterystykę każdego z nich.",
                es: "Conozco los cuatro estilos de aprendizaje y las características de cada uno de ellos.",
                gb: "I understand the four learning styles and the characteristics of each style.",
                it: "Comprendo I quattro stili di apprendimento e le caratteristiche di ognuno."
            }
            , m: 1
        },
        //52
        {
            q: {
                de: "Ich weiß nicht, wie ich die Lernstile meiner Lernenden erkennen kann.",
                ro: "Nu înțeleg cum să recunosc stilurile de învățare ale cursanților mei.",
                pl: "Nie wiem jak rozpoznać konkretne style uczenia się moich studentów.",
                es: "No sé cómo reconocer los estilos de aprendizaje de mis alumnos y alumnas.",
                gb: "I don’t understand how to recognise the learning styles of my learners.",
                it: "Non capisco/so come riconoscere gli stili di apprendimento dei miei studenti."
            }
            , m: -1
        },
        //53
        {
            q: {
                de: "Ich kann eine Reihe von ansprechenden Materialien verwenden, um den verschiedenen Lernstilen gerecht zu werden.",
                ro: "Pot să folosesc o gamă diversă de materiale care să se potrivească fiecărui stil de învăţare.",
                pl: "Potrafię korzystać z szeregu angażujących materiałów, aby dopasować je do różnych stylów uczenia się.",
                es: "Puedo utilizar una serie de materiales dinámicos y atractivos que se adaptan a los diferentes estilos de aprendizaje.",
                gb: "I can use a range of engaging materials to suit the different learning styles.",
                it: "So usare una serie di materiali coinvolgenti per soddisfare I vari stili di apprendimento."
            }
            , m: 1
        },
        //54
        {
            q: {
                de: "Ich kann getrost eine Fülle von Aktivitäten einbauen, die das Behalten des Lernstoffs bei allen meinen Lernenden erleichtern, unabhängig von ihrem Lernstil.",
                ro: "Pot încorpora cu încredere o varietate de activități care facilitează reținerea de informaţie pentru toți participanţii mei, indiferent de stilul lor de învățare.",
                pl: "Mogę śmiało zaproponować mnóstwo zróżnicowanych aktywności, które ułatwią przyswojenie  treści przez wszystkich moich uczniów, niezależnie od ich stylu uczenia się.",
                es: "Soy capaz de emplear fácilmente una gran cantidad de actividades que facilitan la retención de la materia entre todos mis alumnos, independientemente de sus estilos de aprendizaje.",
                gb: "I can confidently incorporate a plethora of activities that facilitate subject retention amongst all my learners regardless of their learning styles.",
                it: "Posso incorporare agevolmente una serie di attività che facilitano la memorizzazione della materia fra I miei studenti, a prescindere dal loro stile di apprendimento."
            }
            , m: 1
        },
        //55
        {
            q: {
                de: "Meine Schüler beschweren sich, dass mein Unterricht nicht ansprechend und unterhaltsam ist.",
                ro: "Elevii se plâng că lecțiile nu sunt atractive și plăcute.",
                pl: "Moi uczniowie narzekają, że moje lekcje nie są przyjemne i angażujące.",
                es: "Mis alumnos se quejan de que mis clases no son atractivas y amenas.",
                gb: "My students complain that my lessons aren’t engaging and enjoyable.",
                it: "I miei studenti si lamentano rispetto al fatto che le mie lezioni non sono coinvolgenti e/o divertenti."
            }
            , m: -1
        },

        //56
        {
            q: {
                de: "Ich weiß nicht, wie ich die volle Aufmerksamkeit meiner Teilnehmer gewinnen kann, wenn ich eine Schulung durchführe.",
                ro: "Nu știu cum să captez întreaga atenție a participanților mei atunci când ţin un curs.",
                pl: "Nie wiem, jak zyskać pełną uwagę moich uczniów, gdy prowadzę szkolenie.",
                es: "No sé cómo captar toda la atención de mi alumnado cuando imparto una formación.",
                gb: "I don’t know how to capture the complete attention of my participants when I am delivering training.",
                it: "Non so come catturare l'attenzione completa dei miei partecipanti quando sto erogando la mia proposta formativa."
            }
            , m: -1
        },
        //57
        {
            q: {
                de: "Ich halte mich immer an das Protokoll und habe verschiedene Strategien, um während des Trainings eine ansprechende Atmosphäre zu schaffen.",
                ro: "Urmăresc mereu protocolul şi am strategii diferite pentru a crea o atmosferă captivantă.",
                pl: "Zawsze postępuję zgodnie z zasadami i znam  kilka strategii, pomagających stworzyć atmosferę angażującą uczestników w prowadzone szkolenie.",
                es: "Siempre sigo el protocolo y dispongo de diferentes estrategias que me ayudan a crear una atmósfera atractiva durante una formación.",
                gb: "I always follow the protocol and have different strategies to create an engaging atmosphere during training.",
                it: "Seguo sempre il protocollo e ho diverse strategie per creare un’atmosfera coinvolgenre durante il training."
            }
            , m: 1
        },
        //58
        {
            q: {
                de: "Ich verwende Strategien und Aktivitäten, um die Erwartungen, Ängste, Gefühle und Einstellungen der Teilnehmer während der Schulungen zu erkunden.",
                ro: "Folosesc strategii și activități pentru a explora așteptările, fricile, sentimentele și atitudinile participanților în cadrul sesiunilor de formare.",
                pl: "Wykorzystuję strategie i działania pomagające zbadać oczekiwania, lęki, uczucia i postawy uczestników podczas sesji szkoleniowych.",
                es: "Utilizo estrategias y actividades para explorar las expectativas, los miedos, los sentimientos y las actitudes de los participantes durante las sesiones de formación.",
                gb: "I use strategies and activities to explore expectations, fears, feelings and attitudes of the participants during training sessions.",
                it: "Uso strategie e attività per indagare paure, aspettative, sentimenti e atteggiamenti dei partecipanti durante le sessioni formative."
            }
            , m: 1
        },
        //59
        {
            q: {
                de: "Ich achte besonders auf die \"Temperatur\" der Gruppe, aber ich kann mein Programmtraining nicht entsprechend dem Feedback und dem Verhalten der Teilnehmer ändern.",
                ro: "Sunt deosebit de atent la \"temperatura\" grupului, dar nu pot să îmi modific programul de formare din mers în funcție de feedback-ul și comportamentul participanților.",
                pl: "Zwracam szczególną uwagę na tzw. \"temperaturę\" grupy, ale nie potrafię zmodyfikować programu szkolenia w zależności od informacji zwrotnej i zachowania uczestników.",
                es: "Suelo prestar especial atención a la \"temperatura\" del grupo, pero no puedo modificar la formación de mi programa en función de las reacciones y el comportamiento de los participantes.",
                gb: "I am particularly attentive to the \"temperature\" of the group but I cannot amend my programme training according to the feedback and behaviour of participants.",
                it: "Sono particolarmente attento nel monitorare la \"temperatura\" del gruppo ma faccio fatica a modificare il programma che mi sono preparato, in base ai feedback e al comportamento dei partecipanti ."
            }
            , m: -1
        },
        //60
        {
            q: {
                de: "Ich konzentriere mich mehr auf die Inhalte, die ich meinen Teilnehmern vermitteln muss, als auf die Art und Weise, wie ich die Schulung durchführe.",
                ro: "Mă concentrez mai mult pe conținutul pe care trebuie să îl împărtășesc participanților mei, decât pe modul în care ofer formarea. ",
                pl: "Bardziej skupiam się na treści, którą mam do przekazania uczestnikom, niż na sposobie prowadzenia szkolenia. ",
                es: "Me centro más en el contenido que tengo que compartir con mis participantes que en la forma en que imparto la formación. ",
                gb: "I am more focused on the content that I have to share with my participants, as oppose to the way I deliver the training. ",
                it: "Sono più concentrato sul contenuto che devo condividere con i miei partecipanti, piuttosto che sul modo in cui gestisco il processo formativo."
            }
            , m: -1
        },
    ];


    const skill_groups = [
        "INTRAPERSONAL SKILLS",
        "INTERPERSONAL SKILLS",
        "HYBRID"
    ]

    const links = {
        "INTRAPERSONAL SKILLS": {
            es: ["https://www.universia.net/es/actualidad/habilidades/adaptabilidad-y-flexibilidad-habilidades-imprescindibles-para-encontrar-empleo.html", "https://www.apa.org/topics/resilience/camino", "https://www.uab.cat/web/la-uab/itinerarios/campus-sis/gestion-de-las-emociones-1345668503075.html#:~:text=La%20autogesti%C3%B3n%20emocional%20es%20una,la%20construcci%C3%B3n%20de%20nuestro%20bienestar", "https://www.linkedin.com/pulse/propuestas-de-autogesti%C3%B3n-emocional-elena-arroyo?originalSubdomain=es", "https://www.iepp.es/confianza-en-uno-mismo/"],
            it: ["https://eures.ec.europa.eu/strengthening-your-soft-skills-doesnt-need-be-hard-2022-08-10_it", "https://eures.ec.europa.eu/feeling-burnt-out-we-can-help-2022-07-28_it", "https://www.youtube.com/watch?v=ukaasRuNXbI", ".https://www.youtube.com/watch?v=lgTW1sTx02s", "https://www.youtube.com/watch?v=IN4PB4LXtas"],
            ro: ["https://suntbine.com/2022/01/26/flexibilitate-psihologica/", "https://www.psymep.ro/ce-este-rezilienta-si-cum-o-poti-antrena/", "https://psihosensus.eu/gestionarea-emotiilor-inteligenta-emotionala/", "https://florinrosoga.ro/cum-sa-cresti-increderea-in-sine/#:~:text=Contabilizează-ți%20succesele%20de%20până%20acum&text=Conștientizarea%20și%20scrierea%20succeselor%20din,mai%20bună%20încredere%20în%20sine.", "http://www.inspire-magazine.ro/5-strategii-prin-care-sa-iti-cresti-stima-si-respectul-de-sine/"],
            gb: ["https://ie.indeed.com/career-advice/career-development/adaptability-skills", "https://www.psychologytoday.com/ie/basics/resilience", "https://www.bishopofllandaff.org/1255/how-to-build-resilience", "https://nextsteptherapy.ie/resources/info-sheets/low-self-esteem/", "https://www.twinkl.ie/teaching-wiki/self-management"],
            pl: ["https://www.praca.pl/poradniki/rynek-pracy/zdolnosci-adaptacyjne-czym-sa-jak-moga-pomoc-w-zyciu-zawodowym_pr-7662.html", "https://pieknoumyslu.com/co-to-jest-zdolnosc-adaptacji-i-dlaczego-jej-potrzebujemy/", "https://redegate.com/10-kluczowa-umiejetnosc-samoswiadomosc/", "http://www.okti.pl/zarzadzanie/zarzadzanie-emocjami", "https://www.kierunekrozwoju.pl/blog/pewnosc-siebie-kluczem-do-sukcesu"],
            de: ["https://eures.ec.europa.eu/strengthening-your-soft-skills-doesnt-need-be-hard-2022-08-10_de", "https://eures.ec.europa.eu/feeling-burnt-out-we-can-help-2022-07-28_de", "https://www.youtube.com/watch?v=ZaQTs4wIokA", "https://www.youtube.com/watch?v=ozKvWc9kjG4", "https://www.youtube.com/watch?v=OzerT48r4Oc"],
        },
        "INTERPERSONAL SKILLS": {
            es: ["https://ctb.ku.edu/es/tabla-de-contenidos/implementar/proveer-informacion-y-destacar-habilidades/resolucion-de-conflicto/principal", "https://www3.gobiernodecanarias.org/medusa/ecoblog/aperjim/resolucion-de-conflictos/", "https://www.youtube.com/watch?v=GidR3MEYIKA", "https://www.youtube.com/watch?v=5WnjvtvuzVA", "https://www.euroinnova.edu.es/blog/actividades-para-motivar-a-los-alumnos"],
            it: ["https://eures.ec.europa.eu/how-sensitively-deliver-negative-feedback-employees-2022-11-25_it", "https://eures.ec.europa.eu/six-soft-skills-you-need-post-pandemic-workplace-2022-04-20_it", "https://eures.ec.europa.eu/how-foster-wellbeing-among-employees-post-covid-19-2021-12-22_it", "https://eures.ec.europa.eu/7-signs-you-could-be-entrepreneur-heart-2019-08-02_it", "https://www.youtube.com/watch?v=mNWyMzC2RiI"],
            ro: ["https://www.andyszekely.ro/5-moduri-de-rezolvare-conflictelor-loc-sa-le-eviti/", "https://cumsa.ro/utile/cum-sa-fii-un-lider-bun-si-ce-trebuie-sa-stii/", "https://www.business-academy.ro/bazei-de-cunostinte/management/stiluri-de-conducere-leadership", "https://www.dpap.ro/blog/modalitati-de-motivare-angajatilor-fara-beneficii-financiare/", "https://www.oanayucel.ro/motivatia-ce-este-si-cum-sa-te-motivezi/"],
            gb: ["https://www.cipd.ie/news-resources/practical-guidance/guides/workplace-conflict#gref", "https://www.cseas.per.gov.ie/wp-content/uploads/Conflict-Resolution-Skills-for-Managers-RPC006265_EN_WB_L_1.pdf", "https://www.nextgeneration.ie/blog/2018/03/the-difference-between-leadership-and-management?source=google.com", "https://www.michaelpage.ie/advice/management-advice/development-and-retention/people-management-developing-yourself-and-your", "https://www.hse.ie/eng/about/who/nqpsd/qps-improvement/leadership-skills-for-engaging-staff-in-improving-quality-sept-18.pdf"],
            pl: ["https://www.katarzynapluska.pl/5-sposobow-rozwiazywania-konfliktow/", "https://stylzycia.polki.pl/psychologia,przywodztwo-a-inteligencja-emocjonalna,10054909,artykul.html", "https://szkolacoachingu.edu.pl/baza-wiedzy/dla-biznesu/umiejetnosci-przywodcze/", "https://badaniahr.pl/biblioteka/efektywne-zarzadzanie-zespolem/76", "https://www.enpulse.eu/artykuly/6-wskazowek-jak-budowac-zaangazowanie"],
            de: ["https://eures.ec.europa.eu/how-sensitively-deliver-negative-feedback-employees-2022-11-25_de", "https://eures.ec.europa.eu/six-soft-skills-you-need-post-pandemic-workplace-2022-04-20_de", "https://eures.ec.europa.eu/how-foster-wellbeing-among-employees-post-covid-19-2021-12-22_de", "https://eures.ec.europa.eu/7-signs-you-could-be-entrepreneur-heart-2019-08-02_de", "https://www.youtube.com/watch?v=tJgkHsZv9F4"]
        },
        "HYBRID": {
            es: ["https://www.iepp.es/comunicacion-efectiva/", "https://mejorconsalud.as.com/tolerancia-frustracion-importancia-beneficios-ejercicios/", "https://www.youtube.com/watch?v=cMKfbxh0YBM", "https://blogs.unitec.mx/vida-universitaria/estilos-de-aprendizaje-visual-auditivo-y-kinestesico-cual-eres", "https://www.youtube.com/watch?v=OF_rEVrQHvI"],
            it: ["https://www.youtube.com/watch?v=0_LfSu_C-D0", "https://www.youtube.com/watch?v=FHmfO25f-3o", "https://www.youtube.com/watch?v=Oh4nARc6ClI", "https://www.youtube.com/watch?v=Oh4nARc6ClI", "https://www.youtube.com/watch?v=cu8r0cXEyAQ"],
            ro: ["https://www.scribd.com/document/374813914/Dezvoltarea-Capacitatii-de-Comunicare", "https://www.wall-street.ro/articol/Careers/208215/20-de-sfaturi-si-trucuri-care-te-pot-tine-motivat-in-timp-ce-ti-atingi-obiectivele.html#gref", "https://www.paginadepsihologie.ro/cum-ne-gestionam-frustrarea-experiente-usoare-sau-coplesitoare/", "https://psihiatriebucuresti.ro/managementul-stresului/", "https://recrutaresiselectie.ro/managementul-frustrarilor-la-locul-de-munca/"],
            gb: ["https://dcmlearning.ie/video-content/top-5-communication-skills-and-how-to-improve-them.html", "https://www.universityofgalway.ie/academic-skills/communicationskills/downloads/Top-tips---oral-communication-skills.pdf", "https://www.universalcreativesolutions.com/insights/11-effective-leadership-strategies-to-motivate-your-team", "https://nextsteptherapy.ie/ten-levels-of-anger-management/", "https://www.acetireland.ie/what-is-your-learning-style/"],
            pl: ["http://drsylwiaolszewska.pl/style-uczenia-sie-i-metody-aktywizujace-w-edukacji-doroslych/", "https://hrbusinesspartner.pl/artykul/zmien-frustracje-w-innowacje", "https://www.wsb.com.pl/style-komunikacji/", "https://hrpolska.pl/hr/czytelnia/Zeby-im-sie-chcialo-tak-jak-im-sie-nie-chce", "https://www.e-mentor.edu.pl/artykul/index/numer/31/id/677"],
            de: ["https://www.youtube.com/watch?v=9rU-0-fFdTQ", "https://www.youtube.com/watch?v=VNZPM1qvce0&list=PLzPiBVgAHXijVDasy92X6lZkl0DvFgSEg&index=14", "https://www.youtube.com/watch?v=O8PKJG7nBPQ&list=PLzPiBVgAHXijVDasy92X6lZkl0DvFgSEg&index=21", "https://www.youtube.com/watch?v=6MNiwL3uzms&list=PLzPiBVgAHXijVDasy92X6lZkl0DvFgSEg&index=26", "https://www.stepstone.at/Karriere-Bewerbungstipps/effektive-kommunikation/"]
        }
    }

    const messages = {
        "INTRAPERSONAL SKILLS": {
            "low": {
                de: "Diese Punktzahl bedeutet, dass Sie höchstwahrscheinlich Hilfe benötigen, um einige interpersonelle Fähigkeiten zu verbessern, die Ihre Fähigkeit, Ihre Arbeit richtig zu erledigen, beeinträchtigen. Sie werden davon profitieren, mehr interpersonelle Strategien zu erlernen, um sich an neue Bedingungen und schwierige oder herausfordernde Lebenserfahrungen anzupassen, wie es belastbare Lehrer tun. Berücksichtigen Sie auch die Bedeutung Ihres emotionalen Selbstmanagements, d. h. die Fähigkeit, Ihre eigenen Emotionen zu erkennen, sowie Ihr Selbstwertgefühl und Selbstvertrauen. Denken Sie daran, dass ein Trainer, der sein Selbstmanagement beherrscht und selbstbewusst ist, eher in der Lage ist, eine Atmosphäre zu schaffen, die Einfühlungsvermögen, Konzentration und qualitatives Lernen begünstigt. In dieser Hinsicht wird unser Schulungssystem Ihnen eine Reihe von Fähigkeiten, Strategien und Techniken vermitteln, um ein optimales Lehr-Lern-Umfeld zu schaffen, das Sie sowohl beruflich als auch persönlich zu einem besseren Ausbilder machen wird. Während wir die Entwicklung unseres Trainingssystems zur Verbesserung Ihrer Kompetenzen abschließen, finden Sie hier einige Materialien, mit denen Sie beginnen können, Ihre sozial-emotionalen Fähigkeiten zu verbessern.",
                ro: "Este foarte probabil să ai nevoie de ajutor pentru a-ți îmbunătăți unele competențe intrapersonale care îți afectează capacitatea de a face treaba în mod corespunzător. Vei beneficia de învățarea mai multor strategii intrapersonale pentru a te adapta la noi condiții și la experiențe de viață dificile sau provocatoare, așa cum fac profesorii rezilienţi. De asemenea, trebuie să iei în considerare importanța gestionării emoțiilor proprii, adică abilitatea de a-ți recunoaște propriile emoții, precum și stima de sine și încrederea în tine. Amintește-ți că un trainer care este profesionist în gestionarea propriilor emoții și este încrezător în sine este mai probabil să creeze o atmosferă care favorizează empatia, concentrarea și învățarea calitativă. În acest sens, sistemul nostru de formare vă va oferi o serie de competențe, strategii și tehnici pentru a promova un mediu de învățare-predare optim, care vă va face un trainer mai bun atât profesional cât și personal. Cât timp  finalizăm dezvoltarea sistemului nostru de formare pentru a-ți îmbunătăți competențele, în blogul nostru poţi găsi materiale pentru a începe să îmbunătățiți abilitățile socio-emoționale.",
                pl: "Ten wynik oznacza, że ​​jest bardzo prawdopodobne, że potrzebujesz pomocy w celu poprawy niektórych umiejętności intrapersonalnych, które wpływają na Twoją zdolność do prawidłowego wykonywania pracy. Odniesiesz korzyść z nauczenia się większej liczby strategii intrapersonalnych, aby dostosować się do nowych warunków i trudnych doświadczeń życiowych, tak jak robią to zaprawieni nauczyciele. Musisz także wziąć pod uwagę znaczenie własnego zarządzania emocjami, czyli umiejętności rozpoznawania własnych emocji, a także poczucia własnej wartości i pewności siebie. Pamiętaj, że trener, który jest biegły w zarządzaniu emocjami i jest pewny siebie, z większym prawdopodobieństwem stworzy atmosferę sprzyjającą empatii, skupieniu i wysokiej jakości uczenia się. Pod tym względem nasz system szkoleniowy zapewni Ci szereg umiejętności, strategii i technik promujących optymalne środowisko nauczania-uczenia się, które uczynią Cię lepszym trenerem zarówno zawodowo, jak i osobiście. Podczas gdy my kończymy opracowywać nasz system szkoleniowy, który umożliwi Ci podniesienie Twoich kompetencji, dajemy Ci materiał do rozpoczęcia pracy nad zwiększaniem Twoich umiejętności społeczno-emocjonalnych.",
                es: "Esta puntuación significa que es muy probable que necesites ayuda para mejorar algunas habilidades intrapersonales que están afectando tu capacidad para desempeñar correctamente tu trabajo. Te vendría bien aprender más estrategias intrapersonales para adaptarte a situaciones nuevas y a experiencias vitales difíciles o desafiantes, como hacen los profesores que tienen resiliencia y capacidad de adaptación. También debes tener en cuenta la importancia de tu gestión autoemocional, es decir, la capacidad de reconocer tus propias emociones, así como la autoestima y la confianza en ti mismo/a. Recuerda que un formador que domina la gestión de las emociones y tiene confianza en sí mismo/a probablemente creará una atmósfera que fomente la empatía, la concentración y el aprendizaje cualitativo de sus estudiantes. En este sentido, nuestro sistema de formación te proporcionará una serie de habilidades, estrategias y técnicas para promover un entorno óptimo de enseñanza-aprendizaje, que te convertirá en un mejor formador tanto profesional como personalmente. Mientras terminamos de desarrollar nuestro sistema de formación para mejorar tus competencias, aquí tienes algunos materiales para empezar a trabajar en cómo mejorar tus habilidades socio-emocionales.",
                gb: "It is very likely you need help in order to improve some intrapersonal skills that are affecting your ability to do your job properly. You will  benefit from learning more intrapersonal strategies to adjust yourself to new conditions and difficult or challenging life experiences, as resilient teachers do. Also, you need to take into consideration the importance of your self-emotional management, that is to say, the ability to recognise your own emotions, as well as self-esteem and self-confidence. Remember that a trainer who is proficient in self-emotional management and is self-confident is more likely to create an atmosphere that fosters empathy, focus and qualitative learning. In this regard, our training system will provide you with a series of skills, strategies and techniques to promote an optimal teaching-learning environment, which will make you a better trainer both professionally and personally. While we finish developing our training system to improve your competencies, here is some material to start working on how to enhance your social-emotional skills.",
                it: "Questo punteggio significa che è molto probabile che hai bisogno di aiuto per migliorare alcune abilità intrapersonali che influiscono sulla capacità di svolgere efficacemente il tuo  lavoro. Potresti trarre beneficio dall'apprendimento di ulteriori strategie intrapersonali per poterti adattare a nuove condizioni e ad esperienze di vita difficili o impegnative, come fanno gli insegnanti resilienti. Inoltre, è necessario considerare l'importanza della gestione delle emozioni, ossia la capacità di riconoscere le proprie emozioni, l'autostima e la fiducia in se stessi. Ricorda che un formatore abile nella gestione delle emozioni e sicuro di sé avrà più probabilità di creare un'atmosfera favorevole per l'empatia, la concentrazione e l'apprendimento di qualità. A questo proposito, il nostro sistema di formazione ti fornirà una serie di competenze, strategie e tecniche per promuovere un ambiente di insegnamento-apprendimento ottimale, che ti renderà un formatore migliore a livello professionale e personale. Mentre ultimiamo lo sviluppo del nostro sistema formativo per migliorare le tue competenze, ecco del materiale per iniziare a lavorare su come migliorare le  tue abilità socio-emotive."

            },
            "medium": {
                de: "Eine mittlere Punktzahl bedeutet, dass Sie möglicherweise bereits über einige gute interpersonelle Fähigkeiten verfügen und sich ihrer Auswirkungen während Ihrer Ausbildung bewusst sind. Sie werden jedoch wahrscheinlich davon profitieren, mehr darüber zu lernen, wie Sie Ihre Fähigkeiten optimieren können. Vielleicht erkennen Sie die entscheidende Rolle, die Belastbarkeit, Flexibilität und Anpassungsfähigkeit spielen, wenn Sie mit Szenarien umgehen müssen, die sich Ihrer Kontrolle entziehen, oder wenn Sie auf unerwartete Verhaltensweisen Ihrer Schüler eingehen müssen. Eine Änderung Ihres inneren Dialogs und die Anwendung spezifischer Strategien und Techniken zur Bewältigung dieser Umstände könnten Ihre negative Einstellung und damit auch die Lehr- und Lernerfahrung verbessern. In diesem Sinne wird unser Trainingssystem ein sehr nützliches Werkzeug sein, um in Ihre innere Gefühlswelt einzutauchen. Während wir die Entwicklung unseres Trainingssystems zur Verbesserung Ihrer Kompetenzen abschließen, finden Sie hier einige Materialien, mit denen Sie beginnen können, Ihre sozial-emotionalen Fähigkeiten zu verbessern.",
                ro: "Aveți deja unele competențe intrapersonale bune și sunteți conștient de efectele lor în timpul practicii dvs. Cu toate acestea, probabil că veți beneficia de învățarea unor lucruri noi despre cum să optimizaţi competențele dvs. Recunoașteţi rolul crucial pe care reziliența, flexibilitatea și adaptabilitatea îl joacă atunci când se lucrează cu scenarii care nu sunt sub controlul dvs. sau când se sunt prezente unele comportamente neașteptate din partea participanţilor. Astfel, schimbarea dialogului interior și adoptarea unor strategii și tehnici specifice pentru a face față acestor circumstanțe ar putea îmbunătăți perspectiva negativă și, prin urmare, experiența de învățare a participanţilor. În acest sens, sistemul nostru de instruire va fi un instrument foarte util pentru a vă cufunda în lumea emoțională interioară. În timp ce lucrăm la dezvoltarea sistemului nostru de instruire pentru a vă îmbunătăți competențele, vă oferim câteva materiale pentru a începe să lucrați la îmbunătățirea competențelor sociale și emoționale.",
                pl: "Średni wynik oznacza, że ​​możesz mieć już dobre umiejętności intrapersonalne i jesteś świadomy jakie efekty przynoszą podczas szkoleń. Jednak prawdopodobnie zyskasz, jeśli dowiesz się więcej o tym, jak zoptymalizować swoje umiejętności. Być może zdajesz sobie sprawę z kluczowej roli, jaką odgrywają odporność, elastyczność i zdolność adaptacji w sytuacjach, nad którymi nie masz kontroli, lub w przypadku nieoczekiwanych zachowań uczniów. Zatem zmiana wewnętrznego dialogu i przyjęcie określonych strategii i technik radzenia sobie z tymi okolicznościami może poprawić twoje negatywne nastawienie, a tym samym doświadczenie w nauczaniu i uczeniu się. W tym sensie nasz system treningowy będzie bardzo przydatnym narzędziem do zagłębiania się w Twój wewnętrzny emocjonalny świat. Podczas gdy my kończymy opracowywać nasz system szkoleniowy w celu podnoszenia Twoich kompetencji, dajemy Ci materiał do rozpoczęcia pracy nad zwiększaniem umiejętności społeczno-emocjonalnych.",
                es: "Una puntuación media significa que puede que ya dispongas de buenas habilidades intrapersonales y que seas consciente de sus efectos durante tu formación. Sin embargo, es aconsejable que aprendas algo más sobre cómo optimizar tus habilidades. Es posible que reconozcas el papel crucial que desempeñan la resiliencia, la flexibilidad y la adaptabilidad cuando te enfrentas a escenarios que no están bajo tu control o cuando abordas algunos comportamientos inesperados de tus alumnos. Así, cambiar tu diálogo interior y adoptar estrategias y técnicas específicas para afrontar estas circunstancias podría mejorar tu enfoque negativo y, por tanto, la experiencia de enseñanza-aprendizaje. En este sentido, nuestro sistema de formación será una herramienta muy útil para profundizar en tu mundo emocional. Mientras terminamos de desarrollar nuestro sistema de formación para mejorar tus competencias, aquí tienes material para empezar a trabajar en cómo mejorar tus habilidades socio-emocionales.",
                gb: "You may already have some good intrapersonal skills and are aware of their effects during your training. However, you will likely benefit from learning more about how to optimise your skills. You might recognise the crucial role that resilience, flexibility and adaptability play when dealing with scenarios that aren’t under your control or when addressing some unexpected behaviours from your students. Thus, changing your inner dialogue and adopting specific strategies and techniques to deal with these circumstances could improve your negative outlook and, therefore, the teaching-learning experience. In this sense, our training system will be a very useful tool to delve into your inner emotional world. While we finish developing our training system to improve your competencies, here is some material to start working on how to enhance your social-emotional skills.",
                it: "Un punteggio intermedio significa che forse hai già buone capacità intrapersonali e sei consapevole dei loro effetti nella tua formazione. Tuttavia, è probabile che ti possa essere utile apprendere nuove possibilità su come ottimizzare le tue abilità. Potresti riconoscere il ruolo cruciale che la resilienza, la flessibilità e l'adattabilità svolgono quando affronti contesti che non sono sotto il tuo controllo o quando affronti comportamenti inaspettati da parte dei tuoi studenti. Pertanto, agendo sul tuo dialogo interiore e adottando strategie e tecniche specifiche per affrontare queste circostanze potresti migliorare la tua visione e, di conseguenza, l'esperienza di insegnamento-apprendimento. In questo senso, il nostro sistema di formazione sarà uno strumento molto utile per addentrarsi nel mondo emotivo interiore. Mentre ultimiamo lo sviluppo del nostro sistema di formazione per migliorare le tue competenze, ecco del materiale per iniziare a lavorare sul potenziamento delle tue abilità socio-emotive."
            },
            "high": {
                de: "Eine hohe Punktzahl bedeutet, dass Sie über große intrapersonelle Qualitäten und Fähigkeiten wie Belastbarkeit, Anpassungsfähigkeit und Flexibilität gegenüber neuen Veränderungen verfügen. Sie haben auch an Ihrem Selbstmanagement und Ihrem Selbstwertgefühl gearbeitet, was dazu beigetragen hat, ein entspanntes und gesundes Lernumfeld für Ihre Schüler zu schaffen. Auch wenn Sie auf einige Schwierigkeiten gestoßen sind, die mit dem Verhalten Ihrer Schüler oder äußeren Umständen zusammenhängen, waren Sie in der Lage, diese zu bewältigen und eine breite Palette interner Maßnahmen zu ergreifen, um diese Probleme zu überwinden.  Wenn Sie noch mehr darüber erfahren möchten, wie Sie Ihre Arbeit optimieren können, können Sie sich unser Schulungssystem ansehen, um neue Fähigkeiten und Strategien zu erlernen, um ein besserer Profi zu werden. Während wir die Entwicklung unseres Schulungs Systems zur Verbesserung Ihrer Kompetenzen abschließen, finden Sie hier einige Materialien, mit denen Sie beginnen können, Ihre sozial-emotionalen Fähigkeiten zu verbessern.",
                ro: "Felicitări! Aveti competențe intrapersonale precum reziliență, adaptabilitate și flexibilitate, dar și o bună gestionare a emoțiilor și o stimă de sine bine dezvoltată. Toate acestea te ajută să creezi un mediu de învățare relaxat și sănătos. De asemenea, chiar și confruntat cu un comportament dificil sau de circumstanțe externe provocatoare, reușești să faci față și să găsești soluții pentru a depăși aceste probleme. Dacă dorești să înveți mai multe despre cum să-ți optimizezi activitatea din punct de vedere socio-emoţional ne poţi urmări în continuare.",
                pl: "Wysoki wynik oznacza, że ​​posiadasz świetne cechy i umiejętności intrapersonalne, takie jak odporność, zdolność adaptacji i elastyczność do nowych zmian. Pracowałeś również nad zarządzaniem emocjami i poczuciem własnej wartości, co pomogło stworzyć przyjazne i zdrowe środowisko uczenia się wśród Twoich uczniów. Co więcej, nawet jeśli napotkałeś pewne trudności związane z zachowaniem ucznia lub okolicznościami zewnętrznymi, byłeś w stanie sobie z nimi poradzić i zastosować szeroki wachlarz wewnętrznych środków w celu przezwyciężenia tych problemów. Jeśli nadal chcesz dowiedzieć się więcej o tym, jak zoptymalizować swoją pracę, możesz zajrzeć do naszego systemu szkoleń, aby nauczyć się nowych umiejętności i strategii, aby stać się jeszcze lepszym profesjonalistą. Podczas, gdy my kończymy prace nad rozwojem naszego systemu szkoleniowego w celu podniesienia Twoich kompetencji, dajemy Ci materiał do rozpoczęcia pracy nad doskonaleniem umiejętności społeczno-emocjonalnych.",
                es: "Una puntuación alta significa que posees grandes cualidades y habilidades intrapersonales tales como la resiliencia, la adaptabilidad y la flexibilidad a los nuevos cambios. Asimismo, has trabajado la gestión de tus emociones y tu autoestima, lo que ha contribuido a crear un ambiente de aprendizaje agradable y positivo entre tus alumnos. Además, aunque hayas encontrado algunas dificultades relacionadas con el comportamiento de tus alumnos o con circunstancias externas, has sido capaz de afrontarlas y de adoptar una amplia gama de mecanismos internos para superar esos problemas.  Si todavía quieres aprender más sobre cómo optimizar tu trabajo, puedes echar un vistazo a nuestro sistema de formación para aprender nuevas habilidades y estrategias para convertirte en un mejor profesional. Mientras terminamos de desarrollar nuestro sistema de formación para mejorar tus competencias, aquí tienes material para empezar a trabajar en cómo perfeccionar tus habilidades socioemocionales.",
                gb: "Congratulations! You have great intrapersonal qualities and skills such as resilience, adaptability and flexibility to new changes. You have also worked on your self-emotional management and self-esteem, which has helped create an easygoing and healthy learning environment among your students. Moreover, even though you might be encountering some difficulties related to your student's behaviour or external circumstances, you were able to cope with those and adopt a wide range of internal measures to overcome those problems.  If you still want to learn more about how to optimise your work, you can have a look at our training system to learn new skills and strategies to become a better professional. While we finish developing our training system to improve your competencies, here is some material to start working on how to sharpen your social-emotional skills.",
                it: "Un punteggio elevato significa che hai grandi qualità e competenze intrapersonali come resilienza, adattabilità e flessibilità ai cambiamenti. Hai già lavorato sulla gestione delle emozioni e sull'autostima, il che ha contribuito a creare un ambiente di apprendimento sereno e sano tra i tuoi studenti. Inoltre, anche se hai incontrato alcune difficoltà legate al comportamento dei tuoi studenti o a d alcune circostanze esterne, sei in grado di affrontarle e adottare un'ampia rosa di misure interne per superare questi problemi.  Se vuoi saperne di più su come ottimizzare il tuo lavoro, puoi dare un'occhiata al nostro sistema di formazione per apprendere nuove competenze e strategie per diventare un professionista migliore. Mentre finiamo di sviluppare il nostro sistema di formazione per migliorare le tue  competenze, ecco del materiale per iniziare a lavorare su come affinare le tue  abilità socio-emotive."
            }
        },
        "INTERPERSONAL SKILLS": {
            "low": {
                de: "Möglicherweise verfügen Sie nicht über die am Arbeitsplatz erforderlichen zwischenmenschlichen Fähigkeiten, was sich auf die Art und Weise auswirkt, wie Sie mit Ihren Schülern kommunizieren und interagieren. Doch lassen Sie sich davon nicht unterkriegen! Es ist wichtig, die Ursachen dafür zu ermitteln, damit Sie gezielte Maßnahmen ergreifen können. Vielleicht verfügen Sie nicht über wirksame Strategien, um mit Stresssituationen oder Konflikten umzugehen, oder Sie sind aufgrund von Problemen, die sich Ihrer Kontrolle entziehen, nicht in der Lage, eine Klasse entsprechend zu leiten. Denken Sie jedoch daran, dass Sie als Lehrkraft über das Potenzial und die Fähigkeiten zur Deeskalation von Situationen verfügen müssen, in denen es zu Gewalt kommen kann. Geben Sie nicht auf! Es gibt viele Werkzeuge, die Sie in unserem Schulungssystem erlernen können, um Führungsqualitäten und die Fähigkeit, andere einzubeziehen, freizusetzen. Während wir die Entwicklung unseres Trainingssystems zur Verbesserung Ihrer Kompetenzen abschließen, finden Sie hier einige Materialien, mit denen Sie beginnen können, Ihre sozial-emotionalen Fähigkeiten zu verbessern.",
                ro: "Este posibil să nu ai abilitățile interpersonale necesare pentru meseria de trainer/educator, ceea ce afectează modul în care comunici și interacționezi cu participanţii tăi. Cu toate acestea, nu te lăsa copleșit de acest lucru! Este important să identifici cauzele acestei situații astfel încât să o poţi îmbunătăţii. Este posibil să nu ai strategii eficiente pentru a face față situațiilor stresante sau conflictelor, sau să nu reușești să gestionezi corespunzător un grup din cauza unor aspecte care sunt dincolo de controlul tău. Dar amintește-ți că, ca şi trainer/educator, este esențial să ai potențialul și abilitățile necesare pentru a dezamorsa situații în care ar putea apărea conflicte sau chiar violenţă. Există o mulțime de instrumente pe care le poți învăța din sistemul nostru de formare pentru a te antrena ca lider și a-ţi spori capacitatea de a îi implica pe alții. În timp ce finalizăm dezvoltarea sistemului nostru de formare pentru a-ți îmbunătăți competențele, iată câteva materiale pentru a începe să lucrezi la modul de îmbunătățire a abilităților tale socio-emoționale.",
                pl: "Możesz nie mieć potrzebnych umiejętności interpersonalnych w miejscu pracy, co w związku z tym wpływa na sposób, w jaki komunikujesz się i wchodzisz w interakcje z uczniami. Jednak nie pozwól, aby to cię przerosło! Ważne jest, aby zidentyfikować przyczyny tego stanu rzeczy, aby móc podjąć konkretne działania. Być może nie masz skutecznych strategii radzenia sobie ze stresującymi sytuacjami lub konfliktami albo nie jesteś w stanie odpowiednio zarządzać grupą z powodu problemów, na które nie masz wpływu. Pamiętaj jednak, że jako edukatorzy bardzo ważne jest, aby mieć potencjał i umiejętności pozwalające na zapobieganie sytuacji, w których może dojść do przemocy. Nie poddawaj się! Istnieje wiele narzędzi, których możesz nauczyć się w naszym systemie szkoleniowym, aby odblokować przywództwo i zdolność do angażowania innych. Podczas gdy my kończymy opracowywać nasz system szkoleniowy w celu podnoszenia Twoich kompetencji, dajemy Ci materiał do rozpoczęcia pracy nad zwiększaniem Twoich umiejętności społeczno-emocjonalnych.",
                es: "Es posible que no tengas las habilidades interpersonales necesarias en tu trabajo, lo que, por tanto, afecta a tu forma de comunicarte e interactuar con tus estudiantes. Sin embargo, ¡no dejes que esto te afecte! Es importante que identifiques las causas para que puedas tomar medidas concretas. Tal vez no cuentes con estrategias eficaces para hacer frente a las situaciones de estrés o a los conflictos, o no seas capaz de gestionar una clase como corresponde debido a cuestiones que están fuera de tu control. Pero recuerda que, como instructores, es esencial tener el potencial y las habilidades para desescalar situaciones en las que pueda haber violencia. ¡No te rindas! Hay muchas herramientas que puedes aprender de nuestro sistema de formación para activar tu liderazgo y la capacidad de involucrar a los demás. Mientras terminamos de desarrollar nuestro sistema de formación para mejorar tus competencias, aquí tienes material para empezar a trabajar en cómo mejorar tus habilidades socio-emocionales.",
                gb: "You might not have the needed interpersonal skills in the workplace which, therefore, affects the way you communicate and interact with your students. However, don’t let this get the better of you! It’s important to identify the causes of this so that you can take a specific action. Maybe you don’t have effective strategies to cope with stressful situations or conflicts, or you aren’t able to manage a classroom accordingly due to issues that are beyond your control. But remember that, as instructors, it is essential to have the potential and skills to de-escalate situations in which violence may occur. Don’t give up! There are lots of tools that you can learn from our training system to unlock leadership and the capacity to engage others. While we finish developing our training system to improve your competencies, here is some material to start working on how to enhance your social-emotional skills.",
                it: "Potresti  non padroneggiare le abilità interpersonali necessarie sul lavoro e questo influisce sul modo in cui comunichi e interagisci con i tuoi  studenti. Tuttavia, non lasciare che ciò abbia la meglio! È importante identificare le cause di questa difficoltà in modo da poter intraprendere un'azione specifica. Forse non padroneggi strategie efficaci per affrontare alcune situazioni di stress e gestione dei conflitti, oppure non sei in grado di gestire una classe in modo adeguato a causa di problemi che sfuggono al tuo controllo. Ma ricorda  che, come formatori, è essenziale avere il potenziale e le competenze per contenere situazioni in cui può innescarsi aggressività. Non arrendeterti! Ci sono molti strumenti che puoi acquisire dal nostro sistema di formazione per sbloccare competenze di leadership e capacità di coinvolgere gli altri. Mentre completiamo lo sviluppo del nostro sistema di formazione per migliorare le  tue competenze, ecco del materiale per iniziare a lavorare su come migliorare le tue abilità socio-emotive."
            },
            "medium": {
                de: "Eine mittlere Punktzahl bedeutet, dass Sie vielleicht bereits über einige gute Fähigkeiten verfügen, um andere einzubeziehen, aber Sie werden wahrscheinlich davon profitieren, mehr darüber zu lernen, wie Sie Ihre Konfliktlösungsfähigkeiten optimieren und die Art und Weise, wie Sie eine Gruppe innerhalb einer Schulung leiten, verbessern können. Andererseits sind wir uns bewusst, dass Sie vielleicht schon einige gute Strategien zur Durchführung von Gruppenaktivitäten anwenden. Die Anwendung anderer spezifischer Strategien und Techniken für diese Bereiche könnte jedoch auch die Lehr-Lern-Erfahrung verbessern. erfen Sie einen Blick auf das SESKAT-Projekt und das neue Schulungssystem, an dem wir derzeit arbeiten und das Ihnen dabei helfen wird, Ihre Konfliktlösung, Ihre Führungsqualitäten, Ihr Menschen- und Gruppenmanagement und Ihre Fähigkeit, andere einzubeziehen, zu verbessern. In der Zwischenzeit können Sie sich das folgende Material ansehen, das gute Hinweise zu diesen Bereichen enthält (Links zu anderen Ressourcen).",
                ro: "Deții deja anumite competențe bune pentru a implica pe alții, dar îți va fi probabil de folos să înveți mai multe despre cum să optimizezi abilitățile tale de rezolvare a conflictelor și să îmbunătățești modul în care gestionezi un grup în cadrul unei formări. Pe de altă parte, foloseşti deja anumite strategii bune pentru a implementa activități de grup. Cu toate acestea, adoptarea mai multor strategii și tehnici specifice pentru a trata aceste aspecte ar putea, de asemenea, să îmbunătățească experiența de învățare-predare. Aruncă un ochi peste proiectul SESKAT și noul sistem de formare la care lucrăm în prezent, care te va ajuta să îți îmbunătățești abilitățile de rezolvare a conflictelor, de lider, de gestionarea persoanelor și a grupurilor, și a capacităţii de a îi implica pe alții. Până când vor elibera cursul, poți verifica următoarele materiale care îți vor oferi resurse bune. (link-uri la alte resurse).",
                pl: "Średni wynik oznacza, że ​​możesz już posiadać dobre umiejętności angażowania innych, ale prawdopodobnie skorzystasz, jeśli dowiesz się więcej o tym, jak zoptymalizować swoje umiejętności rozwiązywania konfliktów i poprawić sposób zarządzania grupą w ramach szkolenia. Z drugiej strony jesteśmy świadomi, że być może już stosujesz dobre strategie do realizacji zajęć grupowych. Jednak przyjęcie innych konkretnych strategii i technik radzenia sobie z tymi obszarami może również poprawić doświadczenie w zakresie nauczania-uczenia się. Przyjrzyj się projektowi SESKAT i nowemu systemowi szkoleniowemu, nad którym obecnie pracujemy, który pomoże Ci usprawnić rozwiązywanie konfliktów, przywództwo, zarządzanie ludźmi i grupą oraz zdolność angażowania innych. W międzyczasie możesz zapoznać się z poniższym materiałem, który dostarczy przydatnych wskazówek w tych obszarach (linki do innych zasobów).",
                es: "Una puntuación media significa que puede que ya tengas la capacidad de involucrar a los demás, pero aún te puedes beneficiar de aprender más sobre cómo optimizar tus habilidades de resolución de conflictos y mejorar la forma de gestionar un grupo dentro de una sesión. Por otra parte, somos conscientes de que puede que ya estés utilizando algunas buenas estrategias para llevar a cabo actividades de grupo. Sin embargo, la adopción de otras estrategias y técnicas específicas para tratar estas áreas también podría ayudarte a mejorar la experiencia de enseñanza-aprendizaje. Echa un vistazo al Proyecto SESKAT y al nuevo sistema de formación en el que estamos trabajando actualmente y que te ayudará a mejorar tu resolución de conflictos, tu liderazgo, tu gestión de personas y grupos y tu capacidad para involucrar a los demás. Mientras tanto, puedes consultar el siguiente material que te dará buenas pistas sobre estas áreas (enlaces a otros recursos).",
                gb: "You may already possess some good skills to engage others, but you will likely benefit from learning more about how to optimise your conflict resolution skills and improve the way you manage a group within a training. On the other hand, we are aware that you might be already using some good strategies to implement group activities. However, adopting other specific strategies and techniques to deal with these areas could also improve the teaching-learning experience. Have a look at the SESKAT Project and the new training system that we are currently working on that will help you improve your conflict resolution, leadership, people and group management, and capacity to engage others. Meanwhile, you can check the following material that will provide good hints on these areas (links to other resources).",
                it: "Un punteggio intermedio significa che forse possiedi già capacità per coinvolgere gli altri, ma probabilmente potresti trarre beneficio nell’apprendere come ottimizzare le tue  capacità di risoluzione dei conflitti, migliorando il modo in cui gestisci un gruppo all'interno di una formazione. Al contempo, siamo consapevoli che potresti già saper usare alcune buone strategie per realizzare attività di gruppo. Tuttavia, l'adozione di altre strategie e tecniche specifiche per affrontare queste aree potrebbe migliorare l'esperienza di insegnamento-apprendimento. Dai un'occhiata al Progetto SESKAT e al nuovo sistema di formazione a cui stiamo lavorando: ti aiuterà a migliorare la risoluzione dei conflitti, la leadership, la gestione delle persone e dei gruppi e la capacità di coinvolgere altri. Nel frattempo, puoi consultare il seguente materiale che ti fornirà buoni spunti su queste aree (link ad altre risorse)."
            },
            "high": {
                de: "Eine hohe Punktzahl bedeutet, dass Sie über eine ausgezeichnete Führungsqualitäten verfügen und wahrscheinlich bereits eine breite Palette von Techniken anwenden, wenn es um die Gestaltung von Gruppenaktivitäten geht. Sie haben es auch geschafft, in schwierigen Situationen mit Konflikten umzugehen, und Sie haben es geschafft, ein gutes Verhältnis zu Ihren Schülern und Studenten aufzubauen. Sie können jedoch immer noch vom SESKAT-Projekt profitieren, und zwar mit unserem Trainingssystem, das Ihnen helfen wird, Ihre zwischenmenschlichen Fähigkeiten zu verbessern, um ein hervorragender Fachmann in den folgenden Bereichen zu werden: Konfliktlösung, Führung, Menschen- und Gruppenmanagement und die Fähigkeit, andere zu begeistern. Während wir die Entwicklung unseres Schulungssystems zur Verbesserung Ihrer Kompetenzen abschließen, finden Sie hier einige Materialien, mit denen Sie beginnen können, diese zwischenmenschlichen Fähigkeiten zu verbessern.",
                ro: "Felicitări! Aveti abilităţi dezvoltate de lider și că deja utilizezi o gamă variată de tehnici atunci când vine vorba să creezi activități de grup. Reușeşti să faci față conflictelor atunci când apar situații dificile și să creezi cu succes un bun raport între tine şi participanţi. Dacă îţi doreşti să aprofundezi cunoaşterea poţi urmări în continuare proiectul SESKAT. În curand vom elibera un sistem de formare care te va ajuta să-ți îmbunătățești abilitățile interpersonale pentru a deveni un profesionist deosebit în următoarele domenii: rezolvarea conflictelor, conducerea, managementul persoanelor și grupurilor, și capacitatea de a îi motiva pe alții. În timp ce finalizăm dezvoltarea sistemului, iată câteva materiale folositoare.",
                pl: "Wysoki wynik oznacza, że jesteś ​​świetnym przywódzcą i prawdopodobnie już korzystasz z szerokiego wachlarza technik, jeśli chodzi o tworzenie zajęć grupowych. Potrafisz także radzić sobie z konfliktami, gdy pojawiają się trudne sytuacje, a także z powodzeniem tworzysz dobre relacje ze swoimi uczniami i pomiędzy nimi. Jednak nadal możesz skorzystać z projektu SESKAT, dzięki naszemu systemowi szkoleń, który pomoże Ci podnieść swoje umiejętności interpersonalne, aby stać się wybitnym profesjonalistą w następujących obszarach: rozwiązywanie konfliktów, przywództwo, zarządzanie ludźmi i grupą oraz umiejętność angażowania innych. Podczas gdy my kończymy opracowywać nasz system szkoleń w celu podnoszenia Twoich kompetencji, dajemy Ci materiał przydatny do rozpoczęcia pracy nad wyostrzeniem powyższych umiejętności interpersonalnych.",
                es: "Una puntuación alta significa que tienes un excelente liderazgo y que probablemente ya estás utilizando una amplia gama de técnicas a la hora de crear actividades de grupo. También has sabido manejar los conflictos cuando han surgido situaciones difíciles y también has conseguido crear una buena relación con y entre tus alumnos. Sin embargo, aún puedes beneficiarte del proyecto SESKAT con nuestro sistema de formación que te ayudará a mejorar tus habilidades interpersonales para convertirte en un profesional excepcional en las siguientes áreas: resolución de conflictos, liderazgo, gestión de personas y grupos, y capacidad para involucrar a los demás. Mientras terminamos de desarrollar nuestro sistema de formación para mejorar tus competencias, aquí tienes material para empezar a trabajar en cómo perfeccionar estas habilidades interpersonales.",
                gb: "Congratulations! You have excellent leadership and are probably already using a wide range of techniques when it comes to creating group activities. You have also managed to cope with conflict when difficult situations arise and you have also successfully created a good rapport with and among your students. However, you can still benefit from the SESKAT project with our training system that will help you upskill your interpersonal skills to become an outstanding professional in the following areas: conflict resolution, leadership, people and group management, and the capacity to engage others. While we finish developing our training system to improve your competencies, here is some material to start working on how to sharpen these interpersonal skills.",
                it: "Un punteggio elevato significa che hai una leadership eccellente e che probabilmente stai già utilizzando un'ampia gamma di tecniche per creare attività di gruppo. Sai anche gestire i conflitti quando si presentano situazioni difficili e creare un buon rapporto con e tra i vostri studenti. Tuttavia, potresti trarre ulteriore vantaggio dal progetto SESKAT tramite il nostro sistema di formazione, che  ti aiuterà a migliorare le tue capacità interpersonali per diventare professionisti eccellenti nelle seguenti aree: risoluzione dei conflitti, leadership, gestione delle persone e dei gruppi e capacità di coinvolgere gli altri.Mentre completiamo lo sviluppo del nostro sistema di formazione per migliorare le  tue competenze, ecco del materiale per iniziare a lavorare sul come affinare queste abilità interpersonali."
            }
        },
        "HYBRID": {
            "low": {
                de: "Diese Punktzahl bedeutet, dass Sie wahrscheinlich Hilfe brauchen, um Ihren Umgang mit Frustrationen bei sich selbst und bei Ihren Schülern zu verbessern. Manchmal ist Ihre Motivation für das Leben und den Beruf nicht optimal, aber das kann sich auch auf die Atmosphäre mit Ihren Schülern auswirken, die Ihre Demotivation sicherlich wahrnehmen können. In einigen Fällen verachten Sie es, sich beim Unterrichten zu begeistern, während Ihre Schüler diese Bereitschaft oder Motivation zum Lernen nicht aufbringen können.  Vor diesem Hintergrund ist es von entscheidender Bedeutung, dass Sie Techniken erlernen, um effektiv mit Ihren Schülern zu kommunizieren und sich auf die Bedürfnisse jedes einzelnen Schülers einzustellen, wenn Sie eine gute Unterrichtsqualität und ein gutes Verhältnis zu den Schülern gewährleisten wollen. Unsere Schulungsmodule können Ihnen dabei helfen, diese spezifischen Bereiche in Bezug auf Selbst- und Fremdmotivation, Frustrationsmanagement, Lernstile und effektive Kommunikationsfähigkeiten zu verbessern. Schauen Sie sich in der Zwischenzeit die folgenden Ressourcen an, die bei der Beschäftigung mit diesen Bereichen von großem Nutzen sein werden (Links zu anderen Ressourcen).",
                ro: "Probabil aveți nevoie de ajutor pentru a îmbunătăți modul în care vă împăcați cu frustrarea din interior cât și din exterior. Uneori, nivelul dvs. de motivație față de viață și de muncă nu poate fi optim, dar acest lucru poate afecta, de asemenea, atmosfera cu participanţii tăi, care pot percepe cu siguranță demotivarea ta. În unele cazuri, deși te simți entuziasmat atunci când predai, participanţii tăi nu au aceeași voință sau motivație de a învăța. În acest context, învățarea unor tehnici de comunicare eficientă și ajustarea la nevoile fiecărui stil de învățare este esențială dacă doreşti să oferi o calitate bună de predare. Modulele noastre de instruire te pot ajuta să îmbunătățeşti aceste domenii specifice legate de automotivare și motivarea celorlalți, gestionarea frustrarii, stilurile de învățare și abilitățile de comunicare eficiente. Între timp, poţi conlulta resursele următoare care îţi vor fi de mare folos atunci când te afli în sala de curs. (link-uri către alte resurse).",
                pl: "Ten wynik oznacza, że ​​prawdopodobnie będziesz potrzebować pomocy, aby poprawić sposób, w jaki radzisz sobie z frustracją w sobie i wśród uczniów. Czasami Twój poziom motywacji do życia i pracy może nie być optymalny, co może wpływać na atmosferę wśród Twoich uczniów, którzy z pewnością mogą dostrzec Twoją demotywację. Z drugiej strony, w niektórych przypadkach pomimo Twojego entuzjazmu podczas przekazywania wiedzy, Twoi uczniowie mogą nie mieć takiej samej chęci lub motywacji do nauki. W tej sytuacji techniki uczenia się umożliwiające skuteczną komunikację z uczniami i dostosowanie do potrzeb każdego ucznia mają kluczowe znaczenie, jeśli Twoim celem jest zapewnienie dobrej jakości nauczania i dobrych relacji. Nasze moduły szkoleniowe mogą pomóc Ci udoskonalić te konkretne obszary związane z motywacją własną i motywacją innych, zarządzaniem frustracją, stylami uczenia się i skutecznymi umiejętnościami komunikacyjnymi. W międzyczasie zapoznaj się z poniższymi zasobami, które będą bardzo przydatne podczas zajmowania się tymi obszarami (linki do innych zasobów).",
                es: "Esta puntuación significa que probablemente necesitarás ayuda para mejorar la forma en que manejas tu propia frustración y la de tus alumnos. A veces, tu nivel de motivación hacia la vida y tu trabajo puede no ser óptimos, pero esto también puede afectar al ambiente que se respira con tus alumnos, los cuales seguramente percibirán tu desmotivación. En algunos casos, a pesar de sentir entusiasmo al enseñar, tus alumnos, por otro lado, pueden no poseer esa voluntad o motivación por aprender.  En este contexto, aprender técnicas para comunicarte eficazmente con tu alumnado y ajustarte a las necesidades de cada uno de ellos es crucial si quieres brindar una enseñanza y relación interpersonal de buena calidad. Nuestros módulos de formación pueden ayudarte a mejorar estas áreas específicas relacionadas con la automotivación y la motivación de los demás, la gestión de la frustración, los estilos de aprendizaje y las habilidades de comunicación efectiva. Mientras tanto, echa un vistazo a los siguientes recursos que te serán de gran utilidad para tratar estas áreas (enlaces a otros recursos).",
                gb: "You will probably need help in order to improve the way you deal with frustration within yourself and with/among your students. Sometimes, your motivational levels towards life and your job may not be optimal, but this can also affect the atmosphere with your students which can surely perceive your demotivation. In some cases, despise feeling enthusiastic when teaching, your students, on the other hand, might not possess that willingness or motivation to learn.  In this setting, learning techniques to communicate effectively with your students and adjust to every learner’s need is crucial if you want to provide a good-quality of teaching and rapport. Our training modules can help you better these specific areas related to self-motivation and motivation of others, frustration management, learning styles and effective communication skills. In the meantime, have a look at the following resources that will be of great use when dealing with these areas.",
                it: "Ciò  significa che forse  hai bisogno di un aiuto per migliorare la gestione della frustrazione a livello personale e /tra i tuoi studenti. A volte la tua  motivazione nei confronti della vita e del lavoro non è massima e questo può influire anche sull'atmosfera di classe, che possono percepire la demotivazione. In alcuni casi, se non senti entusiasmo quando insegni, i tuoi studenti potrebbero non avere la stessa volontà o motivazione ad apprendere. In merito a ciò, imparare le tecniche per comunicare efficacemente con gli studenti e adattarsi alle loro esigenze è fondamentale se si vuole fornire una buona qualità di insegnamento e di relazione. I nostri moduli di formazione possono aiutarti a migliorare queste aree specifiche legate all'auto-motivazione e alla motivazione altrui, alla gestione delle frustrazioni, agli stili di apprendimento e alle comunicazione efficace. Nel mentre, dai un'occhiata alle seguenti risorse che ti saranno molto utili per affrontare queste problematiche (link ad altre risorse)."
            },
            "medium": {
                de: "Eine mittlere Punktzahl bedeutet, dass Sie die Fähigkeit haben, sich selbst zu motivieren und gleichzeitig andere zu motivieren. Wir sind jedoch sicher, dass Sie noch mehr darüber lernen können, wie Sie die Motivation Ihrer Schüler und sich selbst steigern und sich an die Bedürfnisse jedes Lernenden anpassen können. Andererseits sind wir uns bewusst, dass Sie vielleicht schon einige gute Strategien anwenden, um mit Frustration umzugehen und Ihren Schülern zu helfen, ihre Frustration zu bewältigen, dank Ihrer effektiven Kommunikationsfähigkeiten. Die Anwendung spezifischer Strategien und Techniken zur Bewältigung dieser Bereiche könnte jedoch auch die Lehr-Lern-Erfahrung verbessern. Wir sind sicher, dass unser SESKAT-Schulungssystem die oben genannten Fähigkeiten noch einmal verstärken wird: Eigen- und Fremdmotivation, Frustrationsmanagement, Lernstile und effektive Kommunikationsfähigkeiten. In der Zwischenzeit können Sie sich über diese Links mit den Themen vertraut machen (Links zu anderen Ressourcen).",
                ro: "Ai potenţial pentru a te motiva pe tine şi pe alţii. Dar cu siguranţă că poţi învăţa şi mai mult despre cum să crești motivaţia proprie, a studenţilor şi cum să te adaptezi la nevoile tuturor participanţilor tăi. Pe de altă parte, ai deja câteva strategii eficiente pentru a gestiona frustrarea şi pentru a iţi ajuta participanţii să-şi gestioneze propria frustrare prin abilităţile de comunicare pe care le ai. Cu toate acestea, adoptarea de strategii şi tehnici specifice pentru a trata aceste zone ar putea, de asemenea, să îmbunătăţească experienţa de predare-învăţare. Sistemul pe care il dezvoltăm te va ajuta să îţi dezvolţi automotivarea şi motivarea altora, gestionarea frustrării, stilurile de învăţare şi abilităţile de comunicare eficientă. Până atunci, aruncă un ochi peste aceste link-uri pentru a vă familiariza cu subiectele (link-uri către alte resurse).",
                pl: "Średni wynik oznacza, że ​​możesz być w stanie utrzymać swoją motywację i jednocześnie motywować innych. Ale jesteśmy pewni, że nadal możesz dowiedzieć się więcej o tym, jak zwiększyć motywację swoich uczniów i swoją oraz dostosować swój styl przekazywania wiedzy do potrzeb każdego ucznia. Z drugiej strony jesteśmy świadomi, że możesz już praktykować przydatne strategie radzenia sobie z frustracją i pomagać swoim uczniom radzić sobie z własnymi emocjami, dzięki Twoim umiejętnościom skutecznej komunikacji. Jednak zapoznanie się z dodatkowymi strategiami i technikami radzenia sobie z tymi obszarami może przyczynić się do poprawy doświadczenia w zakresie nauczania-uczenia się. Po raz kolejny jesteśmy pewni, że nasz system szkoleń SESKAT wzmocni wyżej wymienione umiejętności: motywowanie własne i innych, radzenie sobie z frustracją, style uczenia się i skuteczne umiejętności komunikacyjne. W międzyczasie sprawdź te linki, aby zapoznać się z przydatnymi tematami (linki do innych zasobów).",
                es: "Una puntuación media significa que tienes la capacidad de mantenerte motivado y, al mismo tiempo, de motivar a los demás. Pero estamos seguros de que aún puedes aprender más sobre cómo aumentar la motivación de tu alumnado y la tuya propia y adaptarte a las necesidades de cada alumno/a. Por otro lado, somos conscientes de que puede que ya estés empleando algunas buenas estrategias para lidiar con la frustración y ayudar a tus alumnos a gestionar la suya propia gracias a tus habilidades comunicativas eficaces. Sin embargo, la adopción de estrategias y técnicas específicas para tratar estas áreas también podría contribuir a mejorar la experiencia de enseñanza-aprendizaje. Una vez más, estamos seguros de que nuestro sistema de formación SESKAT reforzará estas habilidades citadas anteriormente: automotivación y motivación de los demás, gestión de la frustración, estilos de aprendizaje y habilidades de comunicación efectiva. Mientras tanto, consulte estos enlaces para familiarizarse con los temas (enlaces a otros recursos).",
                gb: "You may have the capacity to keep yourself motivated and, at the same time, motivate others. But we are sure you can still learn more on how to increase your students’ and your motivation and to adapt yourself to every learner’s need. On the other hand, we are aware that you might be already using some good strategies to deal with frustration and help your students manage their own thanks to your effective communication skills. However, adopting specific strategies and techniques to deal with these areas could also improve the teaching-learning experience. Once again, we are sure our SESKAT training system will reinforce these above-mentioned skills: self-motivation and motivation of others, frustration management, learning styles and effective communication skills. In the meantime, check these links to familiarise yourself with the topics.",
                it: "Un punteggio medio significa che sei in grado di mantenere la motivazione e, allo stesso tempo, di motivare gli altri. Ma siamo certi che puoi imparare ancora di più su come aumentare la tua motivazione e quella  dei tuoi studenti, adattandoti alle esigenze di ogni studente. D'altra parte, sappiamo che potresti già utilizzare  alcune strategie per gestire la frustrazione e aiutare gli studenti a gestire la loro, grazie ad efficaci capacità comunicative. Tuttavia, l'adozione di strategie e tecniche specifiche per affrontare queste aree potrebbe anche migliorare l'esperienza di insegnamento-apprendimento. Siamo certi che il nostro sistema di formazione SESKAT potenzierà queste competenze: auto-motivazione e motivazione degli altri, gestione della frustrazione, stili di apprendimento e capacità di comunicazione efficace. Nel mentre, consulta questi link per familiarizzare con gli argomenti (link ad altre risorse)."
            },
            "high": {
                de: "Eine hohe Punktzahl bedeutet, dass Sie über ausgezeichnete Kommunikationsfähigkeiten verfügen und wahrscheinlich bereits eine breite Palette von Techniken im Umgang mit der Demotivation und der mangelnden Selbstmotivation von Schülern anwenden. Sie haben bewiesen, dass Sie in der Lage sind, mit Veränderungen und Unsicherheiten umzugehen, sowohl auf persönlicher Ebene als auch bei Ihren Schülern. Nichtsdestotrotz wird unser SESKAT-Trainingssystem Ihre Fähigkeiten und Fertigkeiten in den folgenden Bereichen verbessern: Selbst- und Fremdmotivation, Frustrationsmanagement, Lernstile und effektive Kommunikationsfähigkeiten. Schauen Sie sich in der Zwischenzeit diese Links an, um diese Fähigkeiten zu schärfen und Ihre sozial-emotionalen Fähigkeiten zu verbessern (Links zu anderen Ressourcen).",
                ro: "Felicitări! Ai abilități de comunicare excepționale, și probabil că deja foloseşti o gamă largă de tehnici atunci când te confruntați cu demotivarean participanţilor sau lipsa auto motivației. Adaptarea la particularitățile fiecărui participant și la stilurile lor de învățare îţi vine uşor și ai dovedit că eşti mai mult decât capabil să adopți o atitudine rezilientă atunci când te confrunți cu schimbări și incertitudine, atât la nivel personal, cât și în rândul altora. Dacă eşti interesat să te îmbunătăţeşti în continuare, sistemul nostru de formare SESKAT te va ajuta cu: auto-motivarea și motivarea altora, gestionarea frustrării, stiluri de învățare și abilități de comunicare eficiente. Până terminăm de creat cursul, poţi accesa aceste link-uri pentru a afla mai multe despre capacitățile socio-emoționale.",
                pl: "Wysoki wynik oznacza, że ​​masz doskonałe umiejętności komunikacyjne i prawdopodobnie już korzystasz z szerokiej gamy technik radzenia sobie z demotywacją i brakiem motywacji uczniów. Dostosowanie się do  stylu uczenia się każdego ucznia nie jest dla Ciebie onieśmielające i udowodniłeś, że jesteś w stanie przyjąć odporną postawę w radzeniu sobie ze zmianami i niepewnością, zarówno na poziomie osobistym, jak i wśród uczniów. Niemniej jednak nasz system szkoleń SESKAT z pewnością podniesie Twoje kompetencje w następujących obszarach: motywacja własna i motywacja innych, radzenie sobie z frustracją, style uczenia się i umiejętności skutecznej komunikacji. W międzyczasie sprawdź te linki, aby wyostrzyć te umiejętności i zwiększyć swoje zdolności społeczno-emocjonalne (linki do innych zasobów).",
                es: "Una puntuación alta significa que tienes una excelente capacidad de comunicación y que probablemente ya estés utilizando un amplio abanico de técnicas a la hora de tratar la desmotivación y la falta de automotivación de tus estudiantes. El hecho de adaptarte a las individualidades y estilos de aprendizaje de cada alumno no te intimida y has demostrado que eres más que capaz de adoptar una actitud resiliente a la hora de afrontar los cambios y la incertidumbre, tanto a nivel personal como de tus alumnos. Sin embargo, nuestro sistema de formación SESKAT seguramente mejorará tus capacidades y habilidades en las siguientes áreas: automotivación y motivación de los demás, gestión de la frustración, estilos de aprendizaje y habilidades de comunicación efectiva. Mientras tanto, consulta estos enlaces para perfeccionar estas habilidades y mejorar tus capacidades socio-emocionales (enlaces a otros recursos).",
                gb: "Congratulations! You have excellent communication skills and are probably already using a wide range of techniques when dealing with students' demotivation and lack of self-motivation. Adapting yourself to every learner’s individuality and learning styles don’t intimidate you and you have proved that you are more than capable to adopt a resilient attitude when dealing with changes and uncertainty, both on a personal level and within your students. Nevertheless, our SESKAT training system will surely upskill your capacities and abilities in the following areas: self-motivation and motivation of others, frustration management, learning styles and effective communication skills. Check these links meanwhile to sharpen these skills and enhance your social-emotional abilities.",
                it: "Un punteggio elevato significa che hai  eccellenti capacità di comunicazione e che probabilmente stai già utilizzando una gamma di tecniche per affrontare la demotivazione e la mancanza di auto-motivazione degli studenti. Adattarti all'individualità e agli stili di apprendimento di ogni studente non ti spaventa e hai dimostrato di essere capace di adottare un atteggiamento resiliente nell’affrontare cambiamenti e incertezze a livello personale e con ii vostri studenti. Tuttavia, il nostro sistema di formazione SESKAT aiuterà sicuramente lo sviluppo ulteriore delle tue capacità e abilità nelle seguenti aree: auto-motivazione e motivazione degli altri, gestione delle frustrazioni, stili di apprendimento e capacità di comunicazione efficace. Consulta questi link per affinare queste abilità e migliorare le tue capacità socio-emotive (link ad altre risorse)."
            }
        }
    }

    const question_groups = {
        de: {
            "1. EFFEKTIVE KOMMUNIKATIONSFÄHIGKEITEN": { start: 0, end: 5, group: 2 },
            "2. ANPASSUNGSFÄHIGKEIT/FLEXIBILITÄT": { start: 5, end: 10, group: 0 },
            "3. KONFLIKTLÖSUNG": { start: 10, end: 15, group: 1 },
            "4. LEADERSHIP": { start: 15, end: 20, group: 1 },
            "5. RESILIENZ": { start: 20, end: 25, group: 0 },
            "6. MANAGEMENT DER EIGENEN EMOTIONEN": { start: 25, end: 30, group: 0 },
            "7. MENSCHEN-/GRUPPENMANAGEMENT": { start: 30, end: 35, group: 1 },
            "8. SELBSTMOTIVATION UND MOTIVATION ANDERER": { start: 35, end: 40, group: 2 },
            "9. SELBSTWERTGEFÜHL UND SELBSTVERTRAUEN": { start: 40, end: 45, group: 0 },
            "10. FRUSTRATIONSMANAGEMENT": { start: 45, end: 50, group: 2 },
            "11. LERNSTIL-MANAGEMENT": { start: 50, end: 55, group: 2 },
            "12. DIE FÄHIGKEIT, ANDERE ZU ENGAGIEREN": { start: 55, end: 60, group: 1 }
        },
        ro: {
            "1. ABILITATI DE COMUNICARE EFICIENTA": { start: 0, end: 5, group: 2 },
            "2. ADAPTABILITATE/FLEXIBILITATE": { start: 5, end: 10, group: 0 },
            "3. GESTIONAREA CONFLICTELOR": { start: 10, end: 15, group: 1 },
            "4. LEADERSHIP": { start: 15, end: 20, group: 1 },
            "5. REZILIENŢA": { start: 20, end: 25, group: 0 },
            "6. MANAGEMENTUL PROPRIILOR EMOŢII": { start: 25, end: 30, group: 0 },
            "7. GESTIONAREA OAMENILOR/ A GRUPURILOR": { start: 30, end: 35, group: 1 },
            "8. AUTOMOTIVAREA ŞI MOTIVAREA ALTORA": { start: 35, end: 40, group: 2 },
            "9. STIMA DE SINE ŞI ÎNCREDEREA ÎN SINE": { start: 40, end: 45, group: 0 },
            "10. MANAGEMENTUL FRUSTRĂRII": { start: 45, end: 50, group: 2 },
            "11. MANAGEMENTUL STILURILOR DE ÎNVĂŢARE": { start: 50, end: 55, group: 2 },
            "12. CAPACITATEA DE A-I CAPTIVA PE CEILALŢI": { start: 55, end: 60, group: 1 }
        },
        gb: {
            "1. EFFECTIVE COMMUNICATION SKILLS": { start: 0, end: 5, group: 2 },
            "2. ADAPTABILITY/FLEXIBILITY": { start: 5, end: 10, group: 0 },
            "3. CONFLICT RESOLUTION": { start: 10, end: 15, group: 1 },
            "4. LEADERSHIP": { start: 15, end: 20, group: 1 },
            "5. RESILIENCE": { start: 20, end: 25, group: 0 },
            "6. SELF-EMOTIONAL MANAGEMENT": { start: 25, end: 30, group: 0 },
            "7. PEOPLE/GROUP MANAGEMENT": { start: 30, end: 35, group: 1 },
            "8. SELF-MOTIVATION AND MOTIVATION OF OTHERS": { start: 35, end: 40, group: 2 },
            "9. SELF-ESTEEM AND SELF-CONFIDENCE": { start: 40, end: 45, group: 0 },
            "10. FRUSTRATION MANAGEMENT": { start: 45, end: 50, group: 2 },
            "11. LEARNING STYLES MANAGEMENT": { start: 50, end: 55, group: 2 },
            "12. CAPACITY TO ENGAGE OTHERS": { start: 55, end: 60, group: 1 }
        },
        it: {
            "1. CAPACITÀ DI COMUNICAZIONE EFFICACE": { start: 0, end: 5, group: 2 },
            "2. ADATTABILITÀ/FLESSIBILITÀ": { start: 5, end: 10, group: 0 },
            "3. RISOLUZIONE DEI CONFLITTI": { start: 10, end: 15, group: 1 },
            "4. LEADERSHIP": { start: 15, end: 20, group: 1 },
            "5. RESILIENZA": { start: 20, end: 25, group: 0 },
            "6. GESTIONE DELLE PROPRIE EMOZIONI": { start: 25, end: 30, group: 0 },
            "7. GESTIONE DI PERSONE E GRUPPI": { start: 30, end: 35, group: 1 },
            "8. AUTOMOTIVAZIONE E MOTIVAZIONE DEGLI ALTRI": { start: 35, end: 40, group: 2 },
            "9. AUTOSTIMA E FIDUCIA IN SE STESSE": { start: 40, end: 45, group: 0 },
            "10. GESTIONE DELLA FRUSTRAZIONE": { start: 45, end: 50, group: 2 },
            "11. GESTIONE DEGLI STILI DI APPRENDIMENTO": { start: 50, end: 55, group: 2 },
            "12. CAPACITA’ DI COINVOLGERE GLI ALTRI": { start: 55, end: 60, group: 1 }
        },
        es: {
            "1. HABILIDADES DE COMUNICACIÓN EFICAZ": { start: 0, end: 5, group: 2 },
            "2. ADAPTABILIDAD/FLEXIBILIDAD": { start: 5, end: 10, group: 0 },
            "3. RESOLUCIÓN DE CONFLICTOS": { start: 10, end: 15, group: 1 },
            "4. LIDERAZGO": { start: 15, end: 20, group: 1 },
            "5. RESILIENCIA": { start: 20, end: 25, group: 0 },
            "6. GESTIÓN AUTO-EMOCIONAL": { start: 25, end: 30, group: 0 },
            "7. GESTIÓN DE PERSONAS/GRUPOS": { start: 30, end: 35, group: 1 },
            "8. AUTO-MOTIVACIÓN Y MOTIVACIÓN DE LOS DEMÁS": { start: 35, end: 40, group: 2 },
            "9. AUTOESTIMA Y CONFIANZA EN UNO MISMO": { start: 40, end: 45, group: 0 },
            "10. GESTIÓN DE LA FRUSTRACIÓN": { start: 45, end: 50, group: 2 },
            "11. GESTIÓN DE ESTILOS DE APRENDIZAJE": { start: 50, end: 55, group: 2 },
            "12. CAPACIDAD DE INVOLUCRAR A LOS DEMÁS": { start: 55, end: 60, group: 1 }
        },
        pl: {
            "1. UMIEJĘTNOŚĆ EFEKTYWNEGO KOMUNIKOWANIA SIĘ": { start: 0, end: 5, group: 2 },
            "2. ADAPTACYJNOŚĆ/ ELASTYCZNOŚĆ": { start: 5, end: 10, group: 0 },
            "3. ROZWIĄZYWANIE KONFLIKTÓW": { start: 10, end: 15, group: 1 },
            "4. PRZYWÓDZTWO": { start: 15, end: 20, group: 1 },
            "5. ODPORNOŚĆ": { start: 20, end: 25, group: 0 },
            "6. ZARZĄDZANIE WŁASNYMI EMOCJAMI": { start: 25, end: 30, group: 0 },
            "7. ZARZĄDZANIE GRUPĄ": { start: 30, end: 35, group: 1 },
            "8. MOTYWACJA WŁASNA I MOTYWOWANIE INNYCH": { start: 35, end: 40, group: 2 },
            "9. SAMOPOCZUCIE I PEWNOŚĆ SIEBIE": { start: 40, end: 45, group: 0 },
            "10. ZARZĄDZANIE FRUSTRACJĄ": { start: 45, end: 50, group: 2 },
            "11. ZARZĄDZANIE STYLAMI UCZENIA SIĘ": { start: 50, end: 55, group: 2 },
            "12. ZDOLNOŚĆ DO ANGAŻOWANIA INNYCH": { start: 55, end: 60, group: 1 }
        },
    };

    const questions_per_page = 5;

    const get_progress = (current_index) => {
        const last_index = current_index + questions_per_page;
        return last_index / questions.length * 100;
    }


    let saved_state = JSON.parse(localStorage.getItem(local_storage_key));

    if (saved_state === null) {
        saved_state = {
            current_index: 0,
            prev_active: false,
            next_active: false,
            submit_active: false,
            questions_answered: new Array(questions.length),
            progress: get_progress(0),
        }
    }


    const [questions_state, set_questions_state] = useState(saved_state);

    const set_questions_state_browser = (state) => {
        localStorage.setItem(local_storage_key, JSON.stringify(state));
        set_questions_state(state);
    }

    const calculate_score = () => {
        let total = { score: {}, skill_groups: {}, max_possible: 240, max_per_skill_group: 80, links: links };

        for (let i = 0; i < skill_groups.length; i++) {
            total.skill_groups[skill_groups[i]] = 0;
        }

        for (let gr in question_groups[props.lang]) {
            let gr_total = 0;
            for (let i = question_groups[props.lang][gr].start; i < question_groups[props.lang][gr].end; i++) {
                let score = (questions[i].m > 0 ? questions_state.questions_answered[i] : max_score - questions_state.questions_answered[i]);
                gr_total += score;
            }


            total.skill_groups[skill_groups[question_groups[props.lang][gr].group]] += gr_total;
            total.score[gr] = gr_total;
        }

        total.messages = {}
        for (let gr in total.skill_groups) {
            let score = total.skill_groups[gr];
            let max = total.max_per_skill_group;
            let percentile = score / max * 100;

            if (percentile >= 90) {
                total.messages[gr] = messages[gr]["high"];
            } else if (percentile >= 60 && percentile < 90) {
                total.messages[gr] = messages[gr]["medium"];
            } else {
                total.messages[gr] = messages[gr]["low"];
            }
        }

        return total;
    }

    const all_pages_completed = () => {
        for (let q of questions_state.questions_answered) {
            if (q === undefined || q === null) {
                return false;
            }
        }
        return true;
    }

    const check_page_completed = (index) => {
        for (let c = index; c < index + questions_per_page; c++) {
            if (!(c in questions_state.questions_answered) || questions_state.questions_answered[c] === null) {
                return false;
            }
        }

        return true;
    }

    const on_change = (question, value, ev) => {
        questions_state.questions_answered[question] = value + 1;
        set_questions_state_browser({
            current_index: questions_state.current_index,
            prev_active: questions_state.current_index > 0,
            next_active: check_page_completed(questions_state.current_index) && questions_state.current_index + questions_per_page < questions.length,
            questions_answered: questions_state.questions_answered,
            progress: questions_state.progress,
            submit_active: all_pages_completed(),
        })
    };

    const on_prev = () => {
        const new_index = questions_state.current_index - questions_per_page;
        const prev_active = new_index > 0;
        const next_active = true;

        window.scrollTo({ top: 0, left: 0 });
        set_questions_state_browser({
            current_index: new_index,
            prev_active: prev_active,
            next_active: next_active,
            questions_answered: questions_state.questions_answered,
            error_message: "",
            progress: get_progress(new_index),
            submit_active: questions_state.submit_active,
        });


    }

    const on_next = () => {

        const prev_active = true;
        const new_index = questions_state.current_index + questions_per_page;

        window.scrollTo({ top: 0, left: 0 });

        set_questions_state_browser({
            current_index: new_index,
            prev_active: prev_active,
            next_active: check_page_completed(new_index) && new_index + questions_per_page < questions.length,
            questions_answered: questions_state.questions_answered,
            progress: get_progress(new_index),
            submit_active: questions_state.submit_active,
        });


    }

    const on_submit = async () => {
        let lang = get_country_lang();

        try {
            const docRef = await addDoc(collection(db, "scores"), {
                lang: lang,
                score: questions_state.questions_answered,
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }

        window.scrollTo({ top: 0, left: 0 });

        props.on_form_finished(calculate_score());
    }

    const nav_button_class = "relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50";
    const prev_class = (questions_state.prev_active ? "" : "opacity-50 cursor-not-allowed ") + nav_button_class;
    const next_class = (questions_state.next_active ? "" : "opacity-50 cursor-not-allowed ") + nav_button_class;

    let submit_button_class = "ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2";
    const submit_class = (questions_state.submit_active ? "" : "hidden ") + submit_button_class;


    const progress_style = {
        width: questions_state.progress.toString() + "%"
    }

    return (
        <>
            <div className="justify-center text-center">

                <h3 className="text-lg font-medium leading-6 text-gray-900">12 Social-emotional skills</h3>
                <p className="mt-1 ext-sm text-gray-500">
                    {Object.keys(question_groups[props.lang])[questions_state.current_index / 5]}
                </p>
                <div className="relative pt-1">
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-violet-200">
                        <div style={progress_style} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-violet-500"></div>
                    </div>
                </div>
            </div>
            <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5">

                {
                    questions.map(q => q.q[props.lang]).splice(questions_state.current_index, questions_per_page).map((q, i) => <Question lang={props.lang} question={q} id={questions_state.current_index + i} onchange={on_change} key={questions_state.current_index + i} answer={questions_state.questions_answered[questions_state.current_index + i]}></Question>)
                }

            </div>

            <div className="pt-5">
                <div className="flex justify-center">
                    <button
                        onClick={on_prev}
                        disabled={!questions_state.prev_active}
                        type="button"
                        className={prev_class}
                    >
                        Prev
                    </button>
                    <button
                        onClick={on_next}
                        disabled={!questions_state.next_active}
                        type="button"
                        className={next_class}
                    >
                        Next
                    </button>
                    <button
                        type="button"
                        onClick={on_submit}
                        disabled={!questions_state.submit_active}
                        className={submit_class}
                    >
                        Submit
                    </button>
                </div>
            </div>

        </>
    )
}
