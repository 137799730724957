import React, { useRef, Fragment, useState } from 'react';


import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

import { collection, addDoc } from "firebase/firestore";
import { db } from './firebase';


const text = {
    "de": {
        "update1": "Wenn Sie über unsere Fortschritte auf dem Laufenden gehalten werden möchten, hinterlassen Sie uns eine E-Mail-Adresse und wir halten Sie auf dem Laufenden.",
        "update2": "You can always unregister if you lose interest.",
        "p1": "Hallo und herzlich willkommen bei SESKAT (Social-Emotional Skills for Adult Trainers), einem von der Europäischen Kommission kofinanzierten Erasmus+-Projekt. SESKAT zielt darauf ab, sozio-emotionale Fähigkeiten bei Erwachsenenausbildern zu entwickeln, damit sie ihre Ausbildung besser verwalten und ihre Qualität verbessern können. Ziel ist es, diese Fähigkeiten sowohl aus intrapersoneller als auch aus interpersoneller Sicht zu verbessern, damit der Ausbilder seine eigenen Emotionen erkennen und regulieren kann, wenn er mit den Schwierigkeiten in der Erwachsenenbildung konfrontiert wird. Gleichzeitig lernt der Ausbilder, mit den Emotionen umzugehen, die in der Gruppe der Lernenden entstehen, und die Risiken und Chancen dieser Emotionen im Ausbildungsprozess zu erkennen. Auf diese Weise wird die Ausbildung ein Erfolg, denn Emotionen sind unsere besten Verbündeten bei der Vermittlung von Konzepten und Inhalten.",
        "p2": "Hierfür möchten wir Sie um Ihre Hilfe bitten. Sie benötigen 8 Minuten Ihrer Zeit, um diesen Fragebogen auszufüllen, der auf Ihrer subjektiven Meinung und Ihren persönlichen Erfahrungen beruht. Es handelt sich nicht um einen Einstufungstest, daher ist keine Antwort falsch. Ihre Meinung ist sehr wichtig und die Ergebnisse Ihrer Antworten werden für die Entwicklung des Ausbildungssystems, das wir in den kommenden Monaten entwerfen werden, von großer Bedeutung sein.",
        "p3": "Sie erhalten umgehend eine E-Mail mit einer Rückmeldung zu Ihren Antworten, in der Ihre Punktzahl in den verschiedenen Bereichen des Fragebogens angegeben ist.",
        "p4": "Die Daten aus diesem Fragebogen werden nur für die interne Entwicklung der Inhalte des SESKAT-Projekts verwendet und sind absolut vertraulich.",
        "p5": "Nochmals vielen Dank und willkommen bei SESKAT!",
        "disclaimer": "Ihre Informationen sind vertraulich. Durch das Absenden Ihrer Daten stimmen Sie der Seskat-Partnerschaft zu, Ihre E-Mail-Adresse und Ihre persönlichen Daten nur für Zwecke innerhalb dieses Projekts und nicht für kommerzielle Zwecke, die sich auf diese Veranstaltung beziehen, gemäß den DSGVO-Bestimmungen der Europäischen Union zu verwenden.",
    },
    "ro": {
        "update1": "Dacă doriți să fiți la curent cu progresul nostru, lăsați-ne o adresă de e-mail și vă vom ține la curent.",
        "update2": "Puteți oricând să vă dezabonați dacă vă pierdeți interesul.",
        "p1": "Salutare, bun venit la SESKAT (Abilități socio-emoționale pentru formatori adulți), un proiect Erasmus+ cofinanțat de Comisia Europeană. SESKAT urmărește să dezvolte abilități socio-emoționale în formatorii adulți, astfel încât să poată gestiona mai bine activitatea lor și să îmbunătățească calitatea acesteia. Scopul este să îmbunătățim aceste abilități din punct de vedere intrapersonal și interpersonale, permițând formatorului să identifice și să regleze propriile sale emoții atunci când se confruntă cu dificultățile implicate în formarea adulților. În același timp, formatorul va învăța cum să gestioneze emoțiile generate în grupul de participanţi și va detecta, de asemenea, riscurile și oportunitățile acestor emoții în procesul formativ. În acest fel, formarea va avea succes deoarece emoțiile sunt cei mai buni aliați ai noștri în transmiterea conceptelor și conținuturilor.",
        "p2": "Pentru aceasta, vă invităm să ne ajutați. Aveți nevoie de 8 minute din timpul dvs. pentru a completa acest chestionar bazat pe opinia subiectivă și experiența personală. Acesta nu este un test de plasare, așa că nici un răspuns nu este greșit. Opinia dumneavoastră este foarte importantă și rezultatele răspunsurilor dvs. vor fi de mare importanță pentru dezvoltarea sistemului de formare pe care îl vom proiecta în lunile următoare.",
        "p3": "Veți primi imediat un raport de feedback despre răspunsurile dumneavoastră, indicând scorul dumneavoastră în diferitele domenii menționate în chestionar.",
        "p4": "Datele din acest chestionar vor fi utilizate numai pentru dezvoltarea internă a conținutului proiectului SESKAT și sunt absolut confidențiale.",
        "p5": "Mulţumim mult şi bun venit în SESKAT!",
        "disclaimer": "Informațiile dumneavoastră sunt confidențiale. Prin trimiterea datelor dumneavoastră, sunteți de acord ca parteneriatul Seskat să vă folosească adresa de e-mail și informațiile personale numai în scopuri din cadrul acestui proiect și nu pentru uz comercial, care are legătură cu acest eveniment, în conformitate cu reglementările GDPR ale Uniunii Europene."
    },
    "gb": {
        "update1": "If you want to be updated on our progress leave us an email address and we will keep you posted.",
        "update2": "You can always unregister if you lose interest.",
        "p1": "Hello, welcome to SESKAT (Social-Emotional Skills for Adult Trainers), an Erasmus+ project cofunded by the European Commission. SESKAT aims to develop socio-emotional skills in adult trainers so that they can better manage their training and improve their quality. The aim is to improve these skills from both an intrapersonal and interpersonal point of view, enabling the trainer to identify and regulate his or her own emotions when faced with the difficulties involved in adult training. The educator, at the same time, will learn how to manage the emotions generated in the group of learners and will also detect the risks and opportunities of those emotions in the formative process. In this way, the training will be a success since emotions are our best allies in the transmission of concepts and contents.",
        "p2": "For this, we would like to ask for your help. You need 8 minutes of your time to   complete   this   questionnaire   based   on   your   subjective   opinion   and personal experience. It is not a placement test, so no answer is incorrect. Your opinion is very important and the results of your answers will be of great importance for the development of the training system that we will design in the coming months.",
        "p3": "You will immediately receive an email with feedback on your answers, indicating your score in the different areas mentioned in the questionnaire.",
        "p4": "The   data   from   this   questionnaire   will   only   be   used   for   the   internal development of the contents of the SESKAT Project and are absolutely confidential.",
        "p5": "Thank you again and welcome to SESKAT!",
        "disclaimer": "Your information is confidential. By submitting your details, you consent to the Seskat partnership to use your email address and personal information only for purposes within this project and not commercial use, which relates to this event, in accordance with the GDPR regulations of the European Union."
    },
    "it": {
        "update1": "Se vuoi essere aggiornato sui nostri progressi lasciaci un indirizzo email e ti terremo aggiornato.",
        "update2": "Puoi sempre annullare la registrazione se perdi interesse.",
        "p1": "Salve, benvenuti a SESKAT (Social-Emotional Skills for Adult Trainers), un progetto Erasmus+ cofinanziato dalla Commissione europea. SESKAT mira a sviluppare le competenze socio-emotive dei formatori per adulti in modo che possano gestire meglio i processi formativi e migliorarne la qualità. L'obiettivo è quello di migliorare tali competenze sia dal punto di vista intra-personale che interpersonale, dando al formatore la possibilità di identificare e  gestire le proprie emozioni davanti alle difficoltà che a volte nascono nella  formazione per adulti. Il formatore/la formatrice imparerà a gestire le emozioni nel gruppo di allievi e a individuare i rischi e le opportunità delle stesse nel processo formativo. Così facendo la formazione avrà esito positivo, dato che le emozioni sono i nostri migliori alleati nella trasmissione di concetti e contenuti.",
        "p2": "Per questo motivo, vorremmo chiedere il tuo supporto. Avrai bisogno di 8 minuti per compilare questo questionario basato sulla tua opinione e sulla tua  esperienza personale. Non si tratta di un test di valutazione, quindi nessuna risposta è sbagliata. La tua opinione è importante e le tue risposte saranno fondamentali per lo sviluppo del sistema di formazione che progetteremo nei prossimi mesi.",
        "p3": "Riceverai immediatamente un'e-mail con feedback sulle tue risposte, che indicherà il vostro punteggio nelle aree menzionate nel questionario.",
        "p4": "I dati del questionario saranno utilizzati esclusivamente per lo sviluppo dei contenuti del Progetto SESKAT, rispettando la tua privacy.",
        "p5": "Grazie ancora e benvenuto/a in SESKAT!",
        "disclaimer": "Your information is confidential. By submitting your details, you consent to the Seskat partnership to use your email address and personal information only for purposes within this project and not commercial use, which relates to this event, in accordance with the GDPR regulations of the European Union."
    },
    "es": {
        "update1": "Si deseas mantenerte al tanto de nuestro proyecto, déjanos una dirección de correo electrónico y te mantendremos informado/a.",
        "update2": "Puedes cancelar tu suscripción siempre que desees.",
        "p1": "Hola, bienvenidos a SESKAT (Social-Emotional Skills for Adult Trainers), un proyecto Erasmus+ cofinanciado por la Comisión Europea. SESKAT pretende desarrollar las habilidades socio-emocionales de los formadores de adultos para que puedan gestionar y mejorar la calidad de los cursos y formaciones que imparten. Se trata de mejorar estas habilidades tanto desde el punto de vista intrapersonal como interpersonal, permitiendo al formador identificar y regular sus propias emociones ante las dificultades que conlleva la formación de adultos. El educador, al mismo tiempo, aprenderá a gestionar las emociones generadas en el grupo de alumnos y también detectará los riesgos y oportunidades de esas emociones en el proceso formativo. De esta forma, la formación será un éxito ya que las emociones son nuestras mejores aliadas en la transmisión de conceptos y contenidos.",
        "p2": "Para ello, nos gustaría pedirte tu ayuda. Necesitamos 8 minutos de tu tiempo para completar este cuestionario basado en tu opinión subjetiva y experiencia personal. No es una prueba de nivel, por lo que ninguna respuesta es incorrecta. Tu opinión es muy importante y los resultados de tus respuestas serán de gran importancia para el desarrollo del sistema de formación que diseñaremos en los próximos meses.",
        "p3": "Recibirás inmediatamente un correo electrónico con información sobre tus respuestas, indicando tu puntuación en las diferentes áreas mencionadas en el cuestionario.",
        "p4": "Los datos de este cuestionario sólo se utilizarán para el desarrollo interno de los contenidos del Proyecto SESKAT y son absolutamente confidenciales.",
        "p5": "¡Gracias de nuevo y bienvenido/a a SESKAT!",
        "disclaimer": "Tus información es confidencial. Al enviar tus datos, autorizas al consorcio Seskat a utilizar tu correo electrónico y tus datos personales únicamente para los fines de este proyecto y no para su uso comercial de acuerdo con la normativa Reglamento General de Protección de Datos de la Unión Europea."
    },
    "pl": {
        "update1": "Jeśli chcesz być na bieżąco informowany o naszych postępach, zostaw nam adres e-mail, a my będziemy Cię informować.",
        "update2": "Zawsze możesz się wyrejestrować, jeśli stracisz zainteresowanie.",
        "p1": "Witamy w projekcie SESKAT (Umiejętności Społeczno-Emocjonalne dla Trenerów Osób Dorosłych), jest to projekt Erasmus+ współfinansowany przez Komisję Europejską. SESKAT ma na celu rozwijanie umiejętności społeczno-emocjonalnych u trenerów osób dorosłych, aby mogli oni lepiej zarządzać swoimi szkoleniami i poprawiać ich  jakość. Celem projektu jest doskonalenie tych umiejętności zarówno z punktu widzenia intrapersonalnego, jak i interpersonalnego, umożliwiając trenerowi identyfikację i regulowanie własnych emocji w obliczu trudności związanych ze szkoleniem osób dorosłych. Edukator nauczy się zarządzać emocjami generowanymi w grupie uczących się, a także dostrzeże zagrożenia i szanse wynikające z tych emocji w procesie formacyjnym. W ten sposób szkolenie zakończy się sukcesem, ponieważ emocje są naszymi najlepszymi sprzymierzeńcami w przekazywaniu pojęć i treści.",
        "p2": "W tym celu chcielibyśmy prosić o Twoją pomoc. Wypełnienie kwestionariusza zajmie około 8 minut, oparty on będzie na Twojej subiektywnej opinii i osobistych doświadczeniach. To nie jest test poziomujący, więc żadna odpowiedź nie jest błędna. Twoja opinia jest dla nas bardzo ważna, a Twoje odpowiedzi będą miały ogromne znaczenie dla rozwoju naszego systemu szkoleniowego, który zaprojektujemy w najbliższych miesiącach.",
        "p3": "Po wypełnieniu kwestionariusza natychmiast otrzymasz wiadomość e-mail z informacją zwrotną podsumowującą Twoje odpowiedzi oraz przedstawiającą Twoje rezultaty w poszczególnych obszarach wymienionych w ankiecie.",
        "p4": "Dane z tego kwestionariusza zostaną wykorzystane wyłącznie do wewnętrznego rozwoju Projektu SESKAT i pozostaną całkowicie poufne.",
        "p5": "Podaj nam swój adres e-mail, aby być na bieżąco informowany o rozwoju projektu SESKAT. W każdym momencie możesz zrezygnować z subskrypcji.",
        "disclaimer": "Twoje informacje są poufne. Przesyłając swoje dane, wyrażasz zgodę na to, aby organizacje partnerskie projektu Seskat wykorzystały Twój adres e-mail i dane osobowe wyłącznie do celów związanych z tym projektem, a nie do użytku komercyjnego związanego z tym wydarzeniem, zgodnie z przepisami RODO Unii Europejskiej."
    }
}

export default function Introduction(props) {
    let mail_ref = useRef(null);

    const [show, setShow] = useState(false)

    const add_mail = async (e) => {
        e.preventDefault();
        let mail = mail_ref.current.value;

        try {
            const docRef = await addDoc(collection(db, "mails"), {
                mail: mail,
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }

        mail_ref.current.value = "";
        setShow(true);
    }

    return (
        <>

            <div className="flex grid justify-center text-center">

                <div
                    aria-live="assertive"
                    className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
                >
                    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={show}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3 w-0 flex-1 pt-0.5">
                                            <p className="text-sm font-medium text-gray-900">Successfully registered!</p>
                                            <p className="mt-1 text-sm text-gray-500">Thank you!</p>
                                        </div>
                                        <div className="ml-4 flex flex-shrink-0">
                                            <button
                                                type="button"
                                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                onClick={() => {
                                                    setShow(false)
                                                }}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
                <p className="lg:mt-5 lg:ml-5 lg:mr-5 indent-2 font-normal">
                    {text[props.lang]["p1"]}
                </p>


                <p className="lg:mt-5 lg:ml-5 lg:mr-5 indent-2 font-normal">
                    {text[props.lang]["p2"]}
                </p>


                <p className="lg:mt-5 lg:ml-5 lg:mr-5 indent-2 font-normal">
                    {text[props.lang]["p3"]}
                </p>


                <p className="lg:mt-5 lg:ml-5 lg:mr-5 indent-2 font-normal">
                    {text[props.lang]["p4"]}
                </p>

                <div className="pt-10">

                    <div >
                        <label htmlFor="email" className="pb-4 block text-sm font-medium text-gray-700">
                            {text[props.lang].update1} <br></br> {text[props.lang].update2}
                        </label>
                        <div className="container grid grid-cols-3">
                            <div className="col-span-1"></div>
                            <div className="col-span-1 relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label
                                    htmlFor="name"
                                    className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                    Email
                                </label>
                                <input
                                    ref={mail_ref}
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="you@example.com"
                                />

                            </div>

                            <div className="col-span-1"></div>
                        </div>

                        <button
                            onClick={add_mail}
                            type="button"
                            className="mt-2 relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                            Register
                        </button>

                        <div className="mt-4">
                            <p className="text-gray-400 text-xs">{text[props.lang]["disclaimer"]}</p>
                        </div>
                    </div>
                </div>



            </div>

            <div className="flex grid justify-center text-center">
                <p className="mt-10 font-semibold">{text[props.lang]["p5"]}</p>

                <div className="flex mt-10 grid grid-col-12 justify-center text-center">
                    <div className="grid-col-4"></div>
                    <button className="grid-col-4 justify-center bg-indigo-600 text-white font-bold py-2 px-4 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={props.on_start}>Start</button>
                    <div className="grid-col-4"></div>
                </div>
            </div>
        </>
    )
}